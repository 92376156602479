
import {getGoogleFinanceUrl, getYahooFinanceUrl} from "../../../mixins/stock-name-conversion";

export default {
    name: "CompanyTickerCell",
    props: {
        cell: {
            type: Object,
            required: true,
        },
    },
    computed: {
        googleFinanceUrl() {
            const val = getGoogleFinanceUrl(this.cell.ticker, this.cell.stockExchange);
            return val;
        },
        yahooFinanceUrl() {
            return getYahooFinanceUrl(this.cell.ticker);
        },
    },
}
