
import SaveButton from "../save-button";
export default {
    name: "EditDescriptionModal",
    components: {SaveButton},
    props: {
        company: {
            type: Object,
            required: true
        },
        id: {
            type: String,
            default: "editDescriptionModal"
        }
    },
    data() {
        return {
            companyWorkingCopy: {},
            loading: false,
        }
    },
    computed: {
        updateUrl() {
            return '/api/companies/' + this.companyWorkingCopy.id;
        },
    },
    watch: {
        company(){
            this.resetWorkingCopy();
        }
    },
    mounted() {
        this.resetWorkingCopy();
    },
    methods: {
        resetWorkingCopy() {
            this.companyWorkingCopy = Object.assign({}, this.company);
        },
        submitForm() {
            this.loading = true;
            this.companyWorkingCopy.companyCharacteristic = this.companyWorkingCopy.companyCharacteristic.map((chara) => {
                return chara["@id"];
            });

            // Do not overwrite this in the future
            this.companyWorkingCopy.ignoreCrunchbase = true;

            this.$axios.patch(this.updateUrl, this.companyWorkingCopy, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            })
                .then((response) => {
                    this.$emit('company-updated');
                }).finally(()=>{
                    this.loading = false;
                });
        },
    }
}
