
import EditBaseGroupFields from "../../groups/edit-base-group-fields";
import LoadingSpinner from "../../loading-spinner";
import iriPreparation from "../../../mixins/iri-preparation";

export default {
    name: "CompanyGroup",
    components: {LoadingSpinner, EditBaseGroupFields},
    mixins: [iriPreparation],
    props: {
        operation: {
            type: String,
            required: true,
        },
        bulkCompanies: {
            type: Array,
            default: () => [],
        },
        filters: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            mostUsedCompanyGroups: [],
            groupsLoading: false,
            selectedGroup: null,
            newGroup: {},
        }
    },
    watch: {
        operation() {
            if (this.operation === 'group_remove' && this.companyGroup) {
                this.selectedGroup = this.companyGroup;
            } else if (this.operation === 'group_existing') {
                this.selectedGroup = null;
                this.groupsLoading = true;
                this.groupsLoading = false;
            } else {
                this.selectedGroup = null;
            }
            this.newGroup = {};
        }
    },
    created() {
        this.loadMostUsedCompanyGroups();
    },
    methods: {
        loadMostUsedCompanyGroups() {
            return this.$axios.get("/api/company_groups/most_used").then(response => {
                this.mostUsedCompanyGroups = response.data["hydra:member"];
            });
        },
        run() {
            return new Promise((resolve, reject) => {
                switch (this.operation) {
                    case "group_existing":

                        // Check if there is a selected Group
                        if (!this.selectedGroup?.id) {
                            reject(new Error("No group selected"));
                            return;
                        }

                        this.$axios.post(`/api/company_groups/bulk_add/${this.selectedGroup.id}`, {
                            companies: this.bulkCompanies.map((c) => c.id)
                        }).then((res) => {
                            const toastId = this.generateUUID();
                            const data = res.data;

                            if (data.success) {
                                this.addToast({
                                    type: "success",
                                    title: "Group Updated",
                                    message: `${this.pluralize(data.added, 'company has', 'companies have')} been added to the group. `
                                        + (data.missing.length > 0 ? `${this.pluralize(data.missing.length, 'company', 'companies')} could not be found.` : "")
                                        + (data.existing.length > 0 ? `${this.pluralize(data.existing.length, 'company was', 'companies were')} already on the list.` : ""),
                                    id: toastId,
                                });
                            } else {
                                this.addToast({
                                    type: "danger",
                                    title: "Group Update Failed",
                                    message: data.message,
                                    id: toastId,
                                });
                            }
                            this.$nextTick(() => {
                                this.toggleToast(toastId);
                            })
                        }).finally(() => {
                            resolve({stayOnPage: false});
                        });
                        break;
                    case "group_new":

                        if (!this.newGroup || !this.newGroup.title) {
                            // this.showInvalidConfigWarning("No Group Selected", "Please select a company group first.");
                            reject(new Error("No title given"));
                            return;
                        }

                        // eslint-disable-next-line no-case-declarations
                        const companyGroupCompanies = [];
                        this.bulkCompanies.forEach((company) => {
                            companyGroupCompanies.push({
                                company: company["@id"]
                            })
                        })
                        this.newGroup.companyGroupCompanies = companyGroupCompanies;
                        this.newGroup.creatingUser = `/api/users/${this.userInfo.id}`
                        this.companyGroupWorkingCopy = this.prepareIri(this.newGroup);
                        // Prepare the sharedWithUsers => IRIs only
                        this.newGroup.sharedWithUsers = this.prepareIri(this.newGroup.sharedWithUsers);
                        this.$axios.post("/api/company_groups", this.newGroup).then(() => {
                            const toastId = this.generateUUID();
                            this.addToast({
                                type: "success",
                                title: "Group Created",
                                message: "Company group successfully created",
                                id: toastId,
                            });
                            this.$nextTick(() => {
                                this.toggleToast(toastId);
                            })
                        }).finally(() => {
                            resolve({stayOnPage: false});
                        });

                        break;
                    case "group_remove":


                        // Check if there is a selected Group
                        if (!this.selectedGroup || !this.selectedGroup.id) {
                            // this.showInvalidConfigWarning("No Group Selected", "Please select a company group first.");
                            reject(new Error("No group selected"));
                            return;
                        }

                        this.loadCompanyGroup(this.selectedGroup.id).then(response => {
                            const companyGroupCompanies = [];
                            const companyGroup = response.data;

                            companyGroup.companyGroupCompanies.forEach((cgc) => {
                                if (!this.bulkCompanies.map(x => x.id).includes(cgc.company.id)) {
                                    companyGroupCompanies.push(cgc["@id"]);
                                }
                            })

                            this.$axios.patch(`/api/company_groups/${companyGroup.id}`, {
                                companyGroupCompanies,
                            }, {
                                headers: {
                                    'Content-Type': 'application/merge-patch+json'
                                }
                            }).then(() => {
                                const toastId = this.generateUUID();
                                this.addToast({
                                    type: "success",
                                    title: "Removed from group",
                                    message: "Companies removed from group",
                                    id: toastId,
                                });
                                this.$nextTick(() => {
                                    this.toggleToast(toastId);
                                })
                            }).finally(() => {
                                resolve({stayOnPage: false});
                            });

                        }).catch((error) => {
                            reject(error);
                        })
                        break;
                    case "group_remove_all":

                        this.$axios.post(`/api/company_groups/remove_from_all`, {
                            filters: this.filters
                        }).then(() => {
                            const toastId = this.generateUUID();
                            this.addToast({
                                type: "success",
                                title: "Group Updated",
                                message: "Companies successfully removed from all associated groups",
                                id: toastId,
                            });
                            this.$nextTick(() => {
                                this.toggleToast(toastId);
                            })
                        }).finally(() => {
                            resolve({stayOnPage: false});
                        });
                        break;
                    default:
                        reject(new Error('Unknown Operation'));
                }
            });
        },
        loadCompanyGroup(id) {
            return this.$axios.get(`api/company_groups/${id}`)
        },
    }
}
