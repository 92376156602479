
import iriPreparation from "../../mixins/iri-preparation";
import SaveButton from "../save-button";
import LoadingSpinner from "../loading-spinner";

export default {
    name: "EditCharacteristicsModal",
    components: {LoadingSpinner, SaveButton},
    mixins: [iriPreparation],
    props: {
        company: {
            type: Object,
            required: true
        },
        id: {
            type: String,
            default: "editCharacteristicsModal"
        }
    },
    data() {
        return {
            companyWorkingCopy: {},
            companyCharacteristics: [],
            saving: false,
            loaded: false,
        }
    },
    computed: {
        updateUrl() {
            return '/api/companies/' + this.companyWorkingCopy.id;
        },
    },
    watch: {
        company() {
            this.resetWorkingCopy();
        }
    },
    mounted() {
        this.resetWorkingCopy();
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            if (!this.loaded) {
                this.loadCompanyCharacteristics();
            }
        })
    },
    methods: {
        resetWorkingCopy() {
            this.companyWorkingCopy = Object.assign({}, this.company);
        },
        submitForm() {
            this.saving = true;
            const finalCompany = structuredClone(this.companyWorkingCopy);
            finalCompany.companyCharacteristic = this.prepareIri(finalCompany.companyCharacteristic);
            this.$axios.patch(this.updateUrl, finalCompany, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            })
                .then((response) => {
                    this.$emit('company-updated');
                }).finally(() => {
                    this.saving = false;
                });
        },
        loadCompanyCharacteristics() {
            this.$axios.get('/api/company_characteristics').then((response) => {
                this.companyCharacteristics = response.data['hydra:member'];
            }).finally(() => {
                this.loaded = true;
            });
        }
    }
}
