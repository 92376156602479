
import moment from "moment";

export default {
    name: "AnalystRatingsCard",
    components: {},
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            companyWorkingCopy: {},
            grades: [],
            rowsUpdated: moment().unix(),
            config: {
                columns: [
                    {
                        headline: "Date of Rating",
                        sort: true
                    }, {
                        headline: "Initiation Date",
                        sort: true
                    }, {
                        headline: "Grading Company",
                        sort: true
                    }, {
                        headline: "Previous Grade",
                        sort: true,
                        breakpoint: "xl"
                    }, {
                        headline: "New Grade",
                        sort: true
                    },
                ],
                pagination: 10
            },
            sortOrder: [{
                index: 0,
                asc: false,
            }]
        }
    },
    computed: {
        gradeRows() {
            return this.grades.map(item => {
                return [
                    {
                        text: moment(item.date, "YYYY-MM-DD").format("MM/DD/YYYY"),
                        sortValue: item.date
                    }, {
                        text: moment(item.initiationDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
                        sortValue: item.initiationDate
                    }, {
                        text: item.gradingCompany
                    }, {
                        text: item.previousGrade
                    }, {
                        text: item.newGrade
                    },
                ]
            });
        },
    },
    watch: {
        internationalFilings() {
            this.$nextTick(() => {
                this.rowsUpdated = moment().unix();
            })
        }
    },
    mounted() {
        this.loadInternationalFilings();
        this.resetWorkingCopy();
    },
    methods: {
        loadInternationalFilings() {
            this.$axios.get("/api/companies/stock_info/" + this.company.id + "/grade")
                .then((response) => {
                    this.grades = response.data;
                })
        },
        resetWorkingCopy() {
            this.companyWorkingCopy = Object.assign({}, this.company);
        },
    }
}
