

import {Modal} from "bootstrap";
import {defineComponent} from "vue";
import SaveButton from "../save-button.vue";
import modals from "../../mixins/modals";

export default defineComponent({
    name: "AddCikModal",
    components: {SaveButton},
    mixins: [modals],
    props: {
        cik: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            company: null,
            loading: false,

        }
    },
    computed: {
        cikToEdit() {
            return this.cik;
        }
    },
    methods: {
        reset() {
            this.$emit("reset");
        },
        submitForm() {
            this.loading = true;
            const cikToSubmit = structuredClone(this.cikToEdit);
            if (this.company) {
                cikToSubmit.company = this.company['@id'];
            }
            cikToSubmit.name = this.cikToEdit.name;
            this.$axios.post("/api/ciks", cikToSubmit)
                .then(() => {
                    const modalElement = this.$refs.modal;
                    const modalInstance = Modal.getInstance(modalElement) || new Modal(modalElement);
                    modalInstance.hide();
                    this.$emit('refreshTable');

                })
                .finally(() => {
                    this.loading = false;
                });

        }
    }
})
