
import SaveButton from "../../../save-button.vue";
import LoadingSpinner from "../../../loading-spinner.vue";
import countries from "../../../../mixins/countries";

export default {
    name: "AddLegalEntity",
    components: {LoadingSpinner, SaveButton},
    mixins: [countries],
    props: {
        id: {
            type: String,
            default: "addLegalEntityModal"
        },
        legalEntityToEdit: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            legalEntityWorkingCopy: null,
        }
    },
    computed: {},
    watch: {
        "legalEntityToEdit.id": {
            handler() {
                this.legalEntityWorkingCopy = structuredClone(this.legalEntityToEdit);
            },
            immediate: true
        },
        legalEntityWorkingCopy: {
            handler(newValue) {
                this.$emit('update:legalEntityToEdit', newValue);
            },
            deep: true,
        },
    },
    mounted() {
        this.loadCountryOptions();
    },
    methods: {
        submitForm() {
            console.log("Executing submit form...");
            this.$emit('submit');
        },
        deleteLegalEntity() {
            if (confirm("You are about to delete the legal entity including all accounts and signatory information. Do you want to continue?")) {
                this.$emit('delete');
            }
        }
    }
}
