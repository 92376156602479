import { render, staticRenderFns } from "./characteristics-card.vue?vue&type=template&id=83d08f62&scoped=true"
import script from "./characteristics-card.vue?vue&type=script&lang=js"
export * from "./characteristics-card.vue?vue&type=script&lang=js"
import style0 from "./characteristics-card.vue?vue&type=style&index=0&id=83d08f62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83d08f62",
  null
  
)

export default component.exports