

import LoadingSpinner from "../loading-spinner.vue";

export default {
    name: "CopyInformationModal",
    components: {LoadingSpinner},
    props: {
        companyIds: {
            type: Array,
            required: true,
        },
        eventId: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            uuid: this.generateUUID(),
            infos: [],
            isLoading: false,
        }
    },
    computed: {},
    watch: {
        companyIds: {
            handler() {
                this.loadInfo();
            },
            immediate: true
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            this.$emit('canceled');
        })
    },
    methods: {
        loadInfo() {
            this.isLoading = true;
            this.$axios.post(`/api/events/${this.eventId}/companies/info`, {
                companyIds: this.companyIds,
            })
                .then(response => {
                    this.infos = response.data;
                    console.log("Response", response.data);
                })
                .finally(() => {
                    this.isLoading = false;
                })
        },
        copyData(data) {
            navigator.clipboard.writeText(data);
            const toastId = this.generateUUID();
            this.addToast({
                type: "success",
                title: "Data copied to clipboard.",
                // message: "",
                id: toastId,
            });
            this.$nextTick(() => {
                this.toggleToast(toastId);
            })
        }
    }
}
