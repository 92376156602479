
import modals from "../../mixins/modals";
import EditCharacteristicsModal from "./edit-characteristics-modal";
import optionButtonTooltip from "~/mixins/option-button-tooltip";


export default {
    name: "CharacteristicsCard",
    components: {EditCharacteristicsModal},
    mixins: [optionButtonTooltip, modals],
    props: {
        characteristics: {
            type: Array,
            required: true
        },
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            modal: null,
            config: {
                columns: [
                    {
                        headline: "Group",
                    },
                    {
                        headline: "Characteristic",
                    }
                ],
                pagination: 5
            }
        }
    },
    computed: {
        rows() {
            return Array.apply([], this.characteristics).map((ca) => {
                return [
                    {
                        text: ca.companyCharacteristicGroup.title
                    },
                    {
                        text: ca.title
                    }
                ]
            })
        }
    },
    methods: {
        showEditForm() {
            this.modal = this.openModal(this.$refs.editCharacteristicsModal);
        },
        companyUpdated() {
            this.$emit('company-updated');
            this.closeModal(this.modal);
        }
    }
}
