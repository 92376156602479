
import SearchSelect from "../search-select";

export default {
    name: "DeleteConfirmModal",
    components: {SearchSelect},
    props: {
        companyId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            confirmed: false,
            uuid: this.generateUUID(),
            selectedCompany: null,
        }
    },
    computed: {
        forSelectAjaxUrl() {
            return "/api/companies/for_select?" + this.buildQueryString({
                exclude: [this.companyId],
            })
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            if (!this.confirmed) {
                this.$emit('canceled', this.item)
            }
            this.confirmed = false;
        })
    },
    methods: {
        confirm() {
            this.confirmed = true;
            this.$emit('delete', this.companyId);
        },
        replace() {
            this.confirmed = true;
            this.$emit('replace', {
                companyId: this.companyId,
                replaceId: this.selectedCompany.id,
            })
        }
    }
}
