
import saveButton from "../save-button";
export default {
    name: "AddCompanyGroupReasonModal",
    components: {saveButton},
    props: {
        ids: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            reason: null,
            saving: false,
            multipleCompanyGroup: this.ids.length > 1,
            cgcToEdit: null,
        }
    },
    mounted() {
        console.log(this.ids, this.multipleCompanyGroup)
        if (!this.multipleCompanyGroup) {
            this.$axios.get("/api/company_group_companies/" + this.ids[0]).then((res) => {
                this.cgcToEdit = res.data;
            }).finally(() => {
                this.reason = this.cgcToEdit.reason;
            }
            )
        }

    },
    methods: {
        save() {
            this.saving = true
            let promise;
            if (!this.multipleCompanyGroup) {
                this.cgcToEdit.reason = this.reason
                promise = this.$axios.patch("/api/company_group_companies/" + this.ids[0], this.cgcToEdit, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                    },
                })
            } else {
                promise = this.$axios.post("/api/company_group_companies/edit_reason", {
                    ids: this.ids,
                    reason: this.reason,
                });
            }
            promise.then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Company Group Reason Edited",
                    message: "Company Group Reason(s) successfully changed",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            }).finally(() => {
                // this.isNew = false;
                this.saving = false;
                this.reason = "";
                this.$emit('updated');
            });
        },
    }
}
