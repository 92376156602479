
import SaveButton from "../../save-button";

export default {
    name: "EditMilestoneModal",
    components: {SaveButton},
    props: {
        id: {
            type: String,
            default: "addMilestoneModal"
        },
        milestoneToEdit: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            milestoneWorkingCopy: {},
            periods: [],
            loading: false,
        }
    },
    computed: {
        updateUrl() {
            return '/api/company_milestones';
        },
        year: {
            get() {
                return this.milestoneWorkingCopy.year
            },
            set(newValue) {
                if (newValue) {
                    this.$set(this.milestoneWorkingCopy, "year", parseInt(newValue));
                } else {
                    this.$set(this.milestoneWorkingCopy, "year", null);
                }
            }
        },
    },
    watch: {
        milestoneToEdit() {
            this.resetWorkingCopy();
        }
    },
    mounted() {
        this.loadPeriods();
        this.resetWorkingCopy();
    },
    methods: {
        loadPeriods() {
            this.$axios.get("/api/company_milestones/periods")
                .then((response) => {
                    this.periods = response.data;
                });
        },
        resetWorkingCopy() {
            this.milestoneWorkingCopy = Object.assign({}, this.milestoneToEdit);
        },
        submitForm() {
            this.loading = true;

            if (this.milestoneWorkingCopy.id > 0) {
                this.$axios.patch(this.updateUrl + '/' + this.milestoneWorkingCopy.id, this.milestoneWorkingCopy, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                })
                    .then((response) => {
                        this.$emit('company-updated');
                    }).finally(() => {
                        this.loading = false;
                        this.$emit('modal-close');
                    });
            } else {
                this.$axios.post(this.updateUrl, this.milestoneWorkingCopy)
                    .then((response) => {
                        this.$emit('company-updated');
                    }).finally(() => {
                        this.loading = false;
                        this.$emit('modal-close');
                    });
            }

        },
    }
}
