
import SaveButton from "../../../save-button.vue";
import AddContactModal from "../../../contacts/add-contact-modal.vue";
import modals from "../../../../mixins/modals";

export default {
    name: "EditLegalEntityAccountSignatory",
    components: {AddContactModal, SaveButton},
    mixins: [modals],
    props: {
        id: {
            type: String,
            default: "editLegalEntityAccountSignatoryModal"
        },
        signatoryToEdit: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            signatoryWorkingCopy: null,
            initialContactOptions: [],
            addContactReset: this.generateUUID(),
            modal: null,
        }
    },
    computed: {},
    watch: {
        "signatoryToEdit.id": {
            handler() {
                this.signatoryWorkingCopy = structuredClone(this.signatoryToEdit);

                if (this.signatoryWorkingCopy.company && this.signatoryWorkingCopy.company.id > 0) {
                    this.$axios.get(`/api/contacts/for_select?company=${this.signatoryWorkingCopy.company.id}`)
                        .then((response) => {
                            this.initialContactOptions = response.data['hydra:member'];
                        })
                        .catch(() => {
                            this.initialContactOptions = [];
                        })
                }
            },
            immediate: true
        },
        signatoryWorkingCopy: {
            handler(newValue) {
                this.$emit('update:signatoryToEdit', newValue);
            },
            deep: true,
        },
    },
    mounted() {
    },
    methods: {
        submitForm() {
            this.$emit('submit');
        },
        deleteSignatory() {
            if (confirm("You are about to remove the signatory. Do you want to continue?")) {
                this.$emit('delete');
            }
        },
        addContact() {
            this.modal = this.openModal(this.$refs.addContactModal);
        },
        async contactCreated(newContact) {
            await this.$nextTick();
            this.$set(this.signatoryWorkingCopy, 'contact', newContact);

            this.closeModal(this.modal);
            await this.$nextTick();
            this.addContactReset = this.generateUUID();
        },
        resetAddContact() {
            this.addContactReset = this.generateUUID();
        },
    }
}
