
import LoadingSpinner from "../loading-spinner";
import {CLASSIFICATION_OPTIONS} from "../../mixins/company-classification";
import CompanyBulkOperationForm from "./company-bulk-operation-form";
import iriPreparation from '~/mixins/iri-preparation';

export default {
    name: "CompanyBulkOperationModal",
    components: {LoadingSpinner, CompanyBulkOperationForm},
    mixins: [iriPreparation],
    props: {
        filters: {
            type: Object,
            default: () => {
                return {}
            }
        },
        companyGroup: {
            type: Object,
            default: null,
        },
        event: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            operation: null,
            groupOption: "existing",
            groupOptions: [
                {value: 'existing', label: 'Existing Group'},
                {value: 'new', label: 'New Group'},
            ],
            running: false,
            bulkCompanies: [],
            loading: false,
            submitting: false,
            step: 1,
            companyCharacteristics: [],
            characteristicOptions: [],
            selectedEvent: null,
            isBdTargetManual: null,
            classificationManual: null,
            CLASSIFICATION_OPTIONS,
        }
    },
    created() {
        this.loadBulkCompanies();
    },
    methods: {
        async loadBulkCompanies() {
            this.loading = true;
            const responseFilters = await this.$axios.post("/api/companies/save_bulk_filters", {filters: this.filters});
            const hash = responseFilters.data.hash;

            this.$axios.get("/api/companies/bulk_companies", {
                params: {
                    hash
                }
            }).then(response => {
                this.bulkCompanies = response.data["hydra:member"];
            }).finally(() => {
                this.loading = false;
            });
        },
        submitted() {
            this.$emit("submitted");
        },
    }
}
