

import loadWhenVisible from "../../../../mixins/loadWhenVisible";
import TableActions from "../../../table-actions.vue";
import ContactName from "../../../contacts/contact-name.vue";

export default {
    name: "AccountArtifacts",
    components: {ContactName, TableActions},
    mixins: [loadWhenVisible],
    props: {
        account: {
            type: Object,
            required: false,
            default: null,
        },
        companyId: {
            type: Number,
            required: false,
            default: null,
        },
        narrow: {
            type: Boolean,
            default: false,
        },
        currentOnly: {
            type: Boolean,
            default: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            isVisible: false,
            config: {
                columns: [
                    {
                        headline: "Legal Entity",
                        hidden: this.account
                    },
                    {
                        headline: "Account",
                        hidden: this.account
                    },
                    {
                        headline: "Urgency",
                        hidden: true,
                    },
                    {
                        headline: "Type",
                        hideHeadlineBreakpoint: "all",
                        classes: "text-primary",
                        sort: true,
                    },
                    {
                        headline: "Type",

                    },
                    {
                        headline: "Title",
                        sort: true,
                    },
                    {
                        headline: "Filename",
                    },
                    {
                        headline: "Upload",
                    },
                    {
                        headline: "Update in",
                        sort: true
                    },
                    {
                        headline: "Reminder",
                    },
                    {
                        headline: "History",
                        breakpoint: "all"
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                        hidden: this.readonly,
                    }
                ],
                search: true,
                pagination: this.pageSize ?? (this.narrow ? 5 : 50),
                ajaxUrl: '/api/legal_entity_account_artifacts/list',
            },
            historyConfig: {
                columns: [
                    {
                        headline: "Type"
                    },
                    {
                        headline: "Title",
                    },
                    {
                        headline: "Filename",
                    },
                    {
                        headline: "Date",
                    },
                    {
                        headline: "User",
                    },
                ],
                search: false,
                pagination: 5,
            },
            preparingEmailForArtifactId: null,
        }
    },
    computed: {
        filters() {
            return {
                legalEntityAccountId: this.account ? this.account.id : null,
                current: this.currentOnly,
                companyId: this.companyId,
            }
        },
    },
    watch: {
        loadDataTrigger(value) {
            if (value) {
                this.isVisible = true;
            }
        },
    },
    methods: {
        editArtifact(id) {
            this.$emit('edit', id);
        },
        deleteArtifact(id) {
            if (confirm("You are about to delete an artifact. Do you want to continue?")) {
                this.$emit('delete', id);
            }
        },
        sendReminder(artifactId) {
            this.preparingEmailForArtifactId = artifactId;
            this.$axios.post(`/api/companies/legal_entity_account_artifact/${artifactId}/reminder`)
                .then((response) => {
                    this.$router.push(`/emails/${response.data.id}`);

                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Email draft created",
                        id: toastId,
                    })
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                })
                .finally(() => {
                    this.preparingEmailForArtifactId = null;
                })
        }
    }
}
