
import {STATUS_PENDING, STATUS_APPROVED, STATUS_REJECTED, STATUS_STRONG_CANDIDATE} from "../../mixins/cik-status";
import LoadingSpinner from "../loading-spinner.vue";

export default {
    name: "CIKStatusUpdateCell",
    components: {LoadingSpinner},
    mixins: [],
    props: {
        cell: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            STATUS_APPROVED,
            STATUS_PENDING,
            STATUS_REJECTED,
            STATUS_STRONG_CANDIDATE,
            updatedCell: null,
            isLoading: false
        }
    },
    computed: {
        status() {
            if (this.updatedCell) {
                return this.updatedCell.status;
            } else {
                return this.cell.status;
            }
        },
        label() {
            if (this.updatedCell) {
                return this.updatedCell.statusLabel;
            } else {
                return this.cell.label;
            }
        }
    },
    watch: {},
    methods: {
        updateStatus(id, status) {
            this.isLoading = true;
            this.$axios.patch(`/api/ciks/${id}`, {status}, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            })
                .then((response) => {
                    this.updatedCell = response.data;
                }).finally(() => {
                    this.isLoading = false;
                });
        },
    }
}
