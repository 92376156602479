import { render, staticRenderFns } from "./company-statistics-card.vue?vue&type=template&id=c99e2f5c&scoped=true"
import script from "./company-statistics-card.vue?vue&type=script&lang=js"
export * from "./company-statistics-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c99e2f5c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkeletonLines: require('/codebuild/output/src214552897/src/web/components/skeleton/skeleton-lines.vue').default})
