
import modals from "../../../mixins/modals";
import TableActions from "../../table-actions";
import EditProjectionModal from "./edit-projection-modal";

export default {
    name: "Projections",
    components: {TableActions, EditProjectionModal},
    mixins: [modals],
    props: {
        company: {
            type: Object,
            required: true,
        },
        projections: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            config: {
                columns: [
                    {
                        headline: "Year",
                        sort: true,
                    }, {
                        headline: "Revenue",
                    }, {
                        headline: "EPS",
                    }, {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: 'end'
                    }
                ],
            },
            modal: null,
            projectionToEdit: null,
        }
    },
    computed: {
        rows() {
            return this.projections.map((projection) => {
                return [
                    {text: projection.year},
                    {text: projection.revenue},
                    {text: projection.eps},
                    {type: 'actions', id: projection.id},
                ];
            });
        }
    },
    methods: {
        openProjectionModal() {
            this.modal = this.openModal(this.$refs.editProjectionModal);
        },
        onModalClose() {
            if (this.modal) {
                this.closeModal(this.modal);
            }
            this.$emit('reload-data');
        },
        deleteProjection(id) {
            if(!this.isUserWithRoleResearchAnalyst) {
                return;
            }
            this.$axios.delete('/api/company_projections/' + id)
                .finally(() => {
                    this.$emit('reload-data');
                });
        },
        editProjection(id) {
            if(!this.isUserWithRoleResearchAnalyst) {
                return;
            }
            this.$axios.get('/api/company_projections/' + id)
                .then((response) => {
                    this.projectionToEdit = response.data;
                    this.openProjectionModal();
                });
        },
        addProjection() {
            if(!this.isUserWithRoleResearchAnalyst) {
                return;
            }
            this.$axios.get('/api/company_projections/template/' + this.company.id)
                .then((response) => {
                    this.projectionToEdit = response.data;
                    this.openProjectionModal();
                });
        }
    }
}
