

import modals from "../../../mixins/modals";
import LoadingSpinner from "../../loading-spinner.vue";
import iriPreparation from "../../../mixins/iri-preparation";
import LegalEntityAccounts from "./legal-entity/accounts.vue";
import AddLegalEntity from "./legal-entity/edit-legal-entity-modal.vue";
import OnboardingStatus from "./legal-entity/onboarding-status.vue";

export default {
    name: "LegalEntities",
    components: {OnboardingStatus, LoadingSpinner, AddLegalEntity, LegalEntityAccounts},
    mixins: [modals, iriPreparation],
    props: {
        company: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            legalEntities: [],
            modal: null,
            legalEntityToEdit: null,
            addLegalEntityKey: "addLegalEntityKey",
            legalEntityWidgetKey: "legalEntityWidgetKey",
            loading: false,
        }
    },
    computed: {
        riskScoreOptions() {
            return {
                colors: ["#20E647"],
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,
                        track: {
                            background: '#333',
                            startAngle: -135,
                            endAngle: 135,
                        },
                        dataLabels: {
                            name: {
                                show: true,
                                color: '#000', // Set text color to black
                            },
                            value: {
                                fontSize: "30px",
                                show: true,
                                color: '#000',
                                formatter(val) {
                                    return val / 25
                                }
                            },
                        }
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        type: "horizontal",
                        shadeIntensity: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: "#20E647",
                                opacity: 1
                            },
                            {
                                offset: 70,
                                color: "#FFA500",
                                opacity: 1
                            },
                            {
                                offset: 100,
                                color: "#FF0000",
                                opacity: 1
                            },
                        ]
                    }
                },
                stroke: {
                    lineCap: "butt"
                },
                labels: ["Risk Score"],

            }
        },
        initiallyActiveLegalEntity() {
            if (this.$route.query.hasOwnProperty('legalEntity') && this.$route.query.legalEntity > 0) {
                console.log("Legal Entity", this.$route.query.legalEntity);
                return parseInt(this.$route.query.legalEntity);
            } else {
                return null;
            }
        }
    },
    watch: {},
    created() {
        this.loadLegalEntities();
    },
    methods: {
        loadLegalEntities() {
            this.loading = true;
            this.$axios.get(`/api/legal_entities?company=${this.company.id}`)
                .then((response) => {
                    this.legalEntities = response.data['hydra:member'];
                })
                .finally(() => {
                    this.legalEntityWidgetKey = this.generateUUID();
                    this.loading = false;
                })
        },
        addLegalEntity() {
            this.addLegalEntityKey = this.generateUUID();
            this.$axios.get(`/api/legal_entities/template/${this.company.id}`)
                .then((response) => {
                    this.legalEntityToEdit = response.data;
                    this.$nextTick(() => {
                        this.modal = this.openModal(this.$refs.addLegalEntityModal);
                    });
                });
        },
        editLegalEntity(legalEntity) {
            this.addLegalEntityKey = this.generateUUID();
            this.legalEntityToEdit = legalEntity;
            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.addLegalEntityModal);
            });
        },
        saveLegalEntity() {
            if (this.modal) {
                this.closeModal(this.modal);
                this.modal = null;
            }

            if (this.legalEntityToEdit.riskScore !== null) {
                this.legalEntityToEdit.riskScore = Number(this.legalEntityToEdit.riskScore);
            }

            const finalLegalEntity = this.prepareIri(structuredClone(this.legalEntityToEdit));

            if (this.legalEntityToEdit.id) {
                this.$axios.patch(`/api/legal_entities/${this.legalEntityToEdit.id}`, finalLegalEntity, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                })
                    .then(() => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Legal Entity has been updated",
                            // message: "",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    })
                    .finally(() => {
                        this.loadLegalEntities();
                    });
            } else {
                this.$axios.post('/api/legal_entities', finalLegalEntity)
                    .then(() => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Legal Entity has been added",
                            // message: "",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    })
                    .finally(() => {
                        this.loadLegalEntities();
                    });
            }
        },
        deleteLegalEntity() {
            if (this.modal) {
                this.closeModal(this.modal);
                this.modal = null;
            }
            this.$axios.delete(`/api/legal_entities/${this.legalEntityToEdit.id}`)
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Legal Entity has been deleted",
                        // message: "",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                })
                .finally(() => {
                    this.loadLegalEntities();
                });
        }
    }
}
