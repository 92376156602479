
import modals from "../../mixins/modals";
import EditDescriptionModal from "./edit-description-modal";
import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "DescriptionCard",
    components: {EditDescriptionModal},
    mixins: [optionButtonTooltip, modals],
    props: {
        company: {
            type: Object,
            required: true
        },
        fromContact: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            modal: null,
        }
    },
    methods: {
        showEditForm() {
            this.modal = this.openModal(this.$refs.editDescriptionModal);
        },
        companyUpdated() {
            this.$emit('company-updated');
            this.closeModal(this.modal);
        }
    }
}
