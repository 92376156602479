
import SaveButton from "../save-button";
import LoadingSpinner from "../loading-spinner";

export default {
    name: "EditServiceProviderModal",
    components: {LoadingSpinner, SaveButton},
    props: {
        id: {
            type: String,
            default: "editServiceProviderModal"
        },
        company: {
            type: Object,
            required: true
        },
        serviceProvider: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            serviceProviderWorkingCopy: {},
            serviceProviderRoleOptionsUrl: '/api/service_provider_roles',
            serviceProviderRoles: [],
            saving: false,
            loaded: false,
        }
    },
    computed: {},
    watch: {
        serviceProvider: {
            handler() {
                this.resetWorkingCopy();
            },
            immediate: true,
        },
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            if (!this.loaded) {
                this.loadServiceProviderRoles();
            }
        })
        this.cancelTokenSource = this.$axios.CancelToken.source();
    },
    methods: {
        resetWorkingCopy() {
            this.serviceProviderWorkingCopy = Object.assign({}, this.serviceProvider);
        },
        submitForm() {
            this.saving = true;

            if (!this.serviceProviderWorkingCopy.engagedCompany.name) {
                this.serviceProviderWorkingCopy.engagedCompany.name = this.serviceProviderWorkingCopy.engagedCompany.nameForSelect
            }

            this.serviceProviderWorkingCopy.engagingCompany = "/api/companies/" + this.company.id;

            if (this.serviceProviderWorkingCopy.engagedCompany && typeof this.serviceProviderWorkingCopy.engagedCompany === 'object') {
                if (this.serviceProviderWorkingCopy.engagedCompany.hasOwnProperty("@id")) {
                    this.serviceProviderWorkingCopy.engagedCompany = this.serviceProviderWorkingCopy.engagedCompany["@id"];
                }
            }

            let promise;
            if (this.serviceProviderWorkingCopy.id > 0) {
                promise = this.$axios.patch('/api/service_providers/' + this.serviceProviderWorkingCopy.id, this.serviceProviderWorkingCopy, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                })
            } else {
                promise = this.$axios.post('/api/service_providers', this.serviceProviderWorkingCopy);

            }

            promise
                .then((response) => {
                    this.$emit('service-providers-updated');
                }).finally(() => {
                    this.saving = false;
                });

        },
        loadServiceProviderRoles() {
            this.$axios.get(this.serviceProviderRoleOptionsUrl).then((response) => {
                this.serviceProviderRoles = response.data['hydra:member'];
            }).finally(() => {
                this.loaded = true;
            });
        },
    }
}
