
import SaveButton from "../save-button";
import CompanyForm from "./company-form";

export default {
    name: "AddCompanyModal",
    components: {CompanyForm, SaveButton},
    props: {
        id: {
            type: String,
            default: "addCompanyModal"
        }
    },
    data() {
        return {
            companyTemplate: null,
            companyToEdit: null,
            loading: false,
            loadingTemplate: false,
        }
    },
    methods: {
        addEmptyCompany() {
            this.$axios.get("/api/companies/empty")
                .then((response) => {
                    this.companyToEdit = response.data;
                });
        },
        addCompanyFromTemplate() {

            this.loadingTemplate = true;

            const params = this.buildQueryString({
                uuid: this.companyTemplate.uuid
            });
            this.$axios.get("/api/companies/prepare?" + params)
                .then((response) => {
                    this.companyToEdit = response.data;
                }).finally(() => {
                    this.loadingTemplate = false;

                });
        },
        reset() {
            this.$emit("reset");
        },
        submitForm() {

            this.loading = true;

            // Send request to server
            this.$axios.post('/api/companies', this.companyToEdit)
                .then((response) => {
                    this.$emit('company-updated');
                }).finally(() => {
                    this.loading = false;
                });

        },
    }
}
