
import SaveButton from "../save-button";

export default {
    name: "EditTransactionModal",
    components: {SaveButton},
    props: {
        id: {
            type: String,
            default: "editTransactionModal"
        },
        company: {
            type: Object,
            required: true
        },
        transaction: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: "ACTIVE",
            validator: function validator(value) {
                return ["ACTIVE", "CLOSED", "MISSED", "LOST"].includes(value);
            }

        },
    },
    data() {
        return {
            transactionWorkingCopy: {},
            loading: false,
        }
    },
    computed: {
        isNew() {
            return this.company.id == null;
        }
    },
    methods: {
        resetWorkingCopy() {
            this.transactionWorkingCopy = Object.assign({}, this.transaction);
        },
        submitForm() {
            this.loading = true;

            // TODO
        }
    }
}
