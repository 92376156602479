
import SaveButton from "../save-button";

export default {
    name: "FileCategoryModal",
    components: {SaveButton},
    props: {
        id: {
            type: String,
            default: "fileCategoryModal"
        },
        file: {
            type: Object,
            default: () => {
            },
        },
        isCompanyFile: {
            type: Boolean,
            required: true,
        },
        isEventFile: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            saving: false,
            workingFile: {},
            categoryTemplate: null,
            mostUsedCategories: null,
        }
    },
    watch: {
        file: {
            handler(newVal, oldVal) {
                this.workingFile = structuredClone(newVal);
            },
        }
    },
    mounted() {
        this.$refs.fileCategoryModal.addEventListener('shown.bs.modal', this.onShowModal)
    },
    methods: {
        onShowModal() {
            this.loadCategoryTemplate();
            this.loadMostUsedFileCategory();
        },
        loadCategoryTemplate() {
            let url;

            if (this.isCompanyFile) {
                url = "/api/company_file_categories/empty";
            } else if (this.isEventFile) {
                url = "/api/event_file_categories/empty";
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "Neither company nor event file!";
            }

            this.$axios.get(url)
                .then((response) => {
                    this.categoryTemplate = response.data;
                });
        },
        loadMostUsedFileCategory() {
            let url;

            if (this.isCompanyFile) {
                url = "/api/company_file_categories/most_used";
            } else if (this.isEventFile) {
                url = "/api/event_file_categories/most_used";
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "Neither company nor event file!";
            }

            this.$axios.get(url).then(response => {
                this.mostUsedCategories = response.data["hydra:member"];
            });
        },
        submitForm() {
            this.saving = true;
            delete this.workingFile.user;

            let currentCategories = [];
            if (this.isCompanyFile) {
                currentCategories = this.workingFile.companyFileCategories;
            } else if (this.isEventFile) {
                currentCategories = this.workingFile.eventFileCategories;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "Neither company nor event file!";
            }

            const finalCategories = [];

            currentCategories.forEach((category) => {
                if (category["search-select-created-option"]) {
                    const finalCategory = structuredClone(this.categoryTemplate);
                    finalCategory.title = category.title;
                    finalCategories.push(finalCategory);
                } else {
                    finalCategories.push(category["@id"]);
                }
            });

            if (this.isCompanyFile) {
                this.workingFile.companyFileCategories = finalCategories;
            } else if (this.isEventFile) {
                this.workingFile.eventFileCategories = finalCategories;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "Neither company nor event file!";
            }

            this.$axios.patch(this.workingFile['@id'], this.workingFile, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).then((response) => {
                this.$emit("categorized");
            }).finally(() => {
                this.saving = false;
            })
        },
    }
}
