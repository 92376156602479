
import moment from "moment";
import SaveButton from "../save-button";
import CompanyForm from "./company-form";

export default {
    name: "EditDetailsModal",
    components: {CompanyForm, SaveButton},
    props: {
        company: {
            type: Object,
            required: true
        },
        id: {
            type: String,
            default: "editDetailsModal"
        }
    },
    data() {
        return {
            removeLogo: false,
            companyWorkingCopy: {},
            // stockExchangeId: null,
            loading: false,
            resetForm: 0,
            showForm: false,
        }
    },
    computed: {
        updateUrl() {
            return '/api/companies/' + this.companyWorkingCopy.id;
        },
        isNew() {
            return this.company.id == null;
        }
    },
    watch: {
        company() {
            this.resetWorkingCopy();
        },
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.showForm = true;
        });

        this.$refs.modal.addEventListener('hide.bs.modal', () => {
            this.showForm = false;
        });
    },
    created() {
        this.resetWorkingCopy();
    },
    methods: {
        /**
         * Create copy of Company which is a property, so that we can modify it.
         */
        resetWorkingCopy() {
            this.companyWorkingCopy = Object.assign({}, this.company);
            this.resetForm = moment().unix();
        },
        submitForm() {

            this.loading = true;


            // convert the Type of foundedIn to int because Input return string...
            this.companyWorkingCopy.foundedIn = parseInt(this.companyWorkingCopy.foundedIn)


            // Take only IDs of objects, not full objects
            if (!this.isNew) {
                this.companyWorkingCopy.companyCharacteristic = this.companyWorkingCopy.companyCharacteristic ? this.companyWorkingCopy.companyCharacteristic.map((chara) => {
                    return chara["@id"];
                }) : [];
            }
            // this.companyWorkingCopy.notes = this.companyWorkingCopy.notes.map((note) => {
            //     return note["@id"];
            // });

            // Send request to server
            if (this.company.id > 0) {
                this.$axios.patch(this.updateUrl, this.companyWorkingCopy, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                }).then((response) => {
                    this.$emit('company-updated');
                }).finally(() => {
                    this.loading = false;
                });
            } else {
                this.$axios.post('/api/companies', this.companyWorkingCopy).then((response) => {
                    this.$emit('company-updated');
                    this.$nextTick(() => {
                        this.$nuxt.$router.replace({path: '/companies/' + response.data.id})
                    });
                }).finally(() => {
                    this.loading = false;
                });
            }

        },
    }
}
