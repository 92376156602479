import { render, staticRenderFns } from "./ATMO-table.vue?vue&type=template&id=8579ead4&scoped=true"
import script from "./ATMO-table.vue?vue&type=script&lang=js"
export * from "./ATMO-table.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8579ead4",
  null
  
)

export default component.exports