

export default {
    name: "ActionItems",
    props: {
        company: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            actionItems: [],
            config: {
                columns: [
                    {
                        headline: "Urgency",
                    },
                    {
                        headline: "Legal Entity",
                    },
                    {
                        headline: "Account",
                    },
                    {
                        headline: "Title",
                    },
                ],
                pagination: 5,
            },
            tableLoading: false,
        }
    },
    computed: {
        rows() {
            return this.actionItems.map((item) => {
                return {
                    cells: [
                        {'text': item.urgency, classes: 'fw-bold'},
                        {'text': item.legalEntityAccount.legalEntity.name},
                        {'text': item.legalEntityAccount.readableName},
                        {'text': item.artifactTitle},
                    ],
                    classes: 'highlight-urgency-' + item.urgency,
                };
            });
        }
    },
    created() {
        this.loadActionItems();
        this.$nuxt.$on('artifact-updated', () => {
            this.loadActionItems();
        });
    },
    beforeDestroy() {
        this.$nuxt.$off('artifact-updated');
    },
    methods: {
        loadActionItems() {
            this.tableLoading = true;
            this.$axios.get("/api/companies/action_items/" + this.company.id)
                .then((response) => {
                    this.actionItems = response.data;
                })
                .finally(() => {
                    this.tableLoading = false;
                });
        }
    }
}
