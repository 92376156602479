
import suggestions from "../../mixins/suggestions";
import FileUploadInput from "../file-upload-input";
import LoadingSpinner from "../loading-spinner";
import Suggestion from "../suggestion";
import {CLASSIFICATION_OPTIONS} from "../../mixins/company-classification";
import HintText from "../hint-text.vue";
import contrastColor from "~/mixins/contrast-color";

export default {
    name: "CompanyForm",
    components: {
        HintText,
        FileUploadInput,
        LoadingSpinner,
        Suggestion,
    },
    mixins: [contrastColor, suggestions],
    model: {
        prop: 'company',
        event: 'companyChange'
    },
    props: {
        company: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            stockExchanges: [],
            // stockExchangeId: null,
            companyCharacteristics: [],
            countries: [],
            countryUrl: "/api/countries",
            countryOptions: [],
            stockExchangesLoaded: false,
            countriesLoaded: false,
            characteristicsLoaded: false,
            companyDuplicates: [],
            contacts: [],
            contactsLoaded: false,
            CLASSIFICATION_OPTIONS,
        }
    },
    computed: {
        companyWorkingCopy: {
            get() {
                return this.company;
            },
            set(value) {
                this.$emit('companyChange', value);
            }
        },
        classificationManual: {
            get() {
                if (this.companyWorkingCopy.classificationManual) {
                    return this.CLASSIFICATION_OPTIONS.find(el => el.value === this.companyWorkingCopy.classificationManual);
                }

                return null;
            },
            set(option) {
                // JS is crazy
                if (typeof option === 'object' && option !== null && Object.hasOwn(option, 'value')) {
                    this.companyWorkingCopy.classificationManual = option.value;
                } else {
                    this.companyWorkingCopy.classificationManual = null;
                }
            }
        },
        isNew() {
            return this.company.id == null;
        },
        primaryColors() {
            const colors = {};
            this.companyDuplicates.forEach((c) => {
                colors[c.id] = c.primaryLogoColor ? c.primaryLogoColor : '#ddd';
            })
            return colors;
        },
        contrastColors() {

            const colors = {};
            this.companyDuplicates.forEach((c) => {

                colors[c.id] = c.primaryLogoColor ? this.getContrastYIQ(c.primaryLogoColor) : this.getContrastYIQ('#ddd')
            })

            return colors;
        },
        numberRowsForAlternativeNames() {
            if (this.companyWorkingCopy.alternativeNames) {
                return this.companyWorkingCopy.alternativeNames.split(/\r?\n/).length + 1;
            } else {
                return 2;
            }
        }
    },
    watch: {
        'companyWorkingCopy.ticker'(newVal, oldVal) {
            // if a ticker is being entered, we want to automatically set the company to public
            if (newVal) {
                this.companyWorkingCopy.isPrivate = newVal.length <= 0;
            }
        },
        'companyWorkingCopy.factsetTicker'(newVal, oldVal) {
            // if a ticker is being entered, we want to automatically set the company to public
            if (newVal) {
                this.companyWorkingCopy.isPrivate = newVal.length <= 0;
            }
        },
        'companyWorkingCopy.isPrivate'(newVal, oldVal) {
            // if a company is set to private, we want to automatically remove the ticker
            if (newVal) {
                this.companyWorkingCopy.ticker = "";
                this.companyWorkingCopy.factsetTicker = "";
            }
        },
        'companyWorkingCopy.name': {
            handler(newVal, oldVal) {
                if (this.isNew && newVal && newVal.length > 3) {
                    const params = this.buildQueryString({
                        term: newVal,
                        limit: 10
                    });
                    clearTimeout(this.duplicateSearchTimeout);
                    this.duplicateSearchTimeout = setTimeout(() => {
                        this.$axios.get('/api/companies/for_select?' + params)
                            .then((response) => {
                                this.companyDuplicates = response.data['hydra:member'];
                            });
                    }, 500);
                } else {
                    this.companyDuplicates = [];
                }
            },
            immediate: true,
        }
    },
    mounted() {
        this.loadStockExchanges();
        this.loadCountryOptions();
        if (this.isNew) {
            this.loadCompanyCharacteristics();
        } else {
            this.loadContacts();
        }
    },
    methods: {
        notIsPrivateAndNoTicker() {
            const isPrivate = this.companyWorkingCopy.isPrivate;
            const hasTicker =
                (this.companyWorkingCopy.ticker !== null && String(this.companyWorkingCopy.ticker).length > 0) ||
                (this.companyWorkingCopy.factsetTicker !== null && String(this.companyWorkingCopy.factsetTicker).length > 0);
            return !(isPrivate && hasTicker);
        },
        loadContacts() {
            this.$axios.get(`/api/contacts/by_company/${this.company.id}`)
                .then(response => {
                    this.contacts = response.data;
                }).finally(() => {
                    this.contactsLoaded = true;
                });
        },
        loadCountryOptions() {
            this.$axios.get(this.countryUrl)
                .then((response) => {
                    this.countryOptions = response.data;
                }).finally(() => {
                    this.countriesLoaded = true;
                });
        },
        loadStockExchanges() {
            this.$axios.get('/api/stock_exchanges').then((response) => {
                this.stockExchanges = response.data['hydra:member'];
                // if(this.companyWorkingCopy && this.companyWorkingCopy.stockExchange) {
                //     this.stockExchangeId = parseInt(this.companyWorkingCopy.stockExchange.replace( /^\D+/g, ''));
                // }
            }).finally(() => {
                this.stockExchangesLoaded = true;
            });
        },
        loadCompanyCharacteristics() {
            this.$axios.get('/api/company_characteristics').then((response) => {
                this.companyCharacteristics = response.data['hydra:member'];
            }).finally(() => {
                this.characteristicsLoaded = true;
            });
        },
    }
}
