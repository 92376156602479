

export default {
    name: "UnfinishedOnboardingCard",
    props: {},
    data() {
        return {
            legalEntities: [],
            config: {
                columns: [
                    {
                        headline: 'Name',
                    },
                    {
                        headline: 'Onboarding Status'
                    }
                ],
                pagination: 5
            },
            tableDataLoading: false
        }
    },
    computed: {
        rows() {
            return this.legalEntities.map((le) => {
                return [
                    {
                        type: 'name',
                        legalEntity: le,
                    },
                    {
                        text: le.onboardingStatus
                    }
                ];
            })
        }
    },
    created() {
        this.loadLegalEntities()
    },
    methods: {
        loadLegalEntities() {
            this.tableDataLoading = true;
            this.$axios.get('/api/legal_entities?onboardingStatus[lt]=4')
                .then(response => {
                    this.legalEntities = response.data['hydra:member'];
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.tableDataLoading = false;
                });
        },
    }

}
