
import moment from "moment";
import SaveButton from "../save-button";
import LoadingSpinner from "../loading-spinner";

export default {
    name: "CompanyBulkOperationModal",
    components: {
        LoadingSpinner,
        SaveButton,
    },
    props: {
        filters: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            rowsUpdated: moment().unix(),
            operation: "delete_company_groups",
            bulkCompanyGroups: [],
            loading: true,
            comparisonComplete: false,

            selectedIntersectionCompanies: [],
            selectedOnlyIn1Companies: [],
            selectedOnlyIn2Companies: [],

            preSelectedIntersection: [],
            preSelectedIntersectionIds: [],
            preSelectedOnlyIn1CompanyIds: [],
            preSelectedOnlyIn1Company: [],
            preSelectedOnlyIn2Company: [],
            preSelectedOnlyIn2CompanyIds: [],

            ajaxRows1: [],
            ajaxRows2: [],
            ajaxRows3: [],
            allItem1 : null,
            allItem2 : null,
            allItem3 : null,
            pageChanged1: false,
            pageChanged2: false,
            pageChanged3: false,
            running: false,
        }
    },
    computed: {
        bulkOptions() {
            const operations = [
                {value: 'delete_company_groups', label: 'Delete Company Groups'},
            ];
            if (this.bulkCompanyGroups.length === 2) {
                operations.push(  {value: 'compare', label: 'Compare Company Groups'})
            }
            return operations;
        },
        selectedRows() {
            const preSelectedIds = this.preSelectedIntersection.concat(this.preSelectedOnlyIn1Company).concat(this.preSelectedOnlyIn2Company).map(row => {
                return {
                    id: row.value
                }
            })

            return this.selectedIntersectionCompanies.concat(this.selectedOnlyIn1Companies).concat(this.selectedOnlyIn2Companies)
                .concat(preSelectedIds);
        },
        config1() {
            return {
                columns: [
                    {
                        headline: "Name",
                        sort: true
                    },
                    {
                        headline: "Ticker",
                        sort: true
                    },
                ],
                ajaxUrl: "/api/company_groups/compare/" + this.bulkCompanyGroups[0].id + "/" + this.bulkCompanyGroups[1].id + "?mode=intersection",
                pagination: 10,
                select: true,
                prettySelect: true,
                selectPosition: "pre",
                selectAll: true,
            };
        },
        config2() {
            return {
                columns: [
                    {
                        headline: "Name",
                        sort: true
                    },
                    {
                        headline: "Ticker",
                        sort: true
                    },
                ],
                ajaxUrl: "/api/company_groups/compare/" + this.bulkCompanyGroups[0].id + "/" + this.bulkCompanyGroups[1].id+ "?mode=onlyIn1",
                pagination: 10,
                select: true,
                prettySelect: true,
                selectPosition: "pre",
                selectAll: true,
            };
        },
        config3() {
            return {
                columns: [
                    {
                        headline: "Name",
                        sort: true
                    },
                    {
                        headline: "Ticker",
                        sort: true
                    },
                ],
                ajaxUrl: "/api/company_groups/compare/" + this.bulkCompanyGroups[0].id + "/" + this.bulkCompanyGroups[1].id + "?mode=onlyIn2",
                pagination: 10,
                select: true,
                prettySelect: true,
                selectPosition: "pre",
                selectAll: true,
            };
        },
        // if filters for table are needed
        filters1: {
            async set(val) {},
            get() {
                return {}
            }
        },
        filters2: {
            async set(val) {},
            get() {
                return {}
            }
        },
        filters3: {
            async set(val) {},
            get() {
                return {}
            }
        }
    },
    watch: {
        filters() {
            this.comparisonComplete = false;
            this.loadBulkCompanyGroups();
        },
        operation() {
            this.comparisonComplete = false;
            this.selectedIntersectionCompanies=[];
            this.selectedOnlyIn1Companies=[];
            this.selectedOnlyIn2Companies=[];

            this.preSelectedIntersection=[];
            this.preSelectedIntersectionIds=[];
            this.preSelectedOnlyIn1CompanyIds=[];
            this.preSelectedOnlyIn1Company=[];
            this.preSelectedOnlyIn2Company=[];
            this.preSelectedOnlyIn2CompanyIds=[];

            this.ajaxRows1=[];
            this.ajaxRows2=[];
            this.ajaxRows3=[];
            this.allItem1 =null;
            this.allItem2 =null;
            this.allItem3 =null;
            this.pageChanged1=false;
            this.pageChanged2=false;
            this.pageChanged3=false;
        },
        selectedIntersectionCompanies(rows) {
            if (!this.pageChanged1 && this.ajaxRows1) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedIntersectionIds.includes(rows[i].id)) {
                        this.preSelectedIntersectionIds.push(rows[i].id);
                    }
                }
                for (let j = 0; j < this.ajaxRows1.length; j++) {
                    const id = this.ajaxRows1[j].id;
                    const index = this.preSelectedIntersectionIds.indexOf(id);
                    if (!rows.map(r => r.id).includes(id) && index !== -1) {
                        this.preSelectedIntersectionIds.splice(index, 1);
                    }
                }
            }
        },
        selectedOnlyIn1Companies(rows) {
            if (!this.pageChanged2 && this.ajaxRows2) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedOnlyIn1CompanyIds.includes(rows[i].id)) {
                        this.preSelectedOnlyIn1CompanyIds.push(rows[i].id);
                    }
                }
                for (let j = 0; j < this.ajaxRows2.length; j++) {
                    const id = this.ajaxRows2[j].id;
                    const index = this.preSelectedOnlyIn1CompanyIds.indexOf(id);
                    if (!rows.map(r => r.id).includes(id) && index !== -1) {
                        this.preSelectedOnlyIn1CompanyIds.splice(index, 1);
                    }
                }
            }
        },
        selectedOnlyIn2Companies(rows) {
            if (!this.pageChanged3 && this.ajaxRows3) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedOnlyIn2CompanyIds.includes(rows[i].id)) {
                        this.preSelectedOnlyIn2CompanyIds.push(rows[i].id);
                    }
                }
                for (let j = 0; j < this.ajaxRows3.length; j++) {
                    const id = this.ajaxRows3[j].id;
                    const index = this.preSelectedOnlyIn2CompanyIds.indexOf(id);
                    if (!rows.map(r => r.id).includes(id) && index !== -1) {
                        this.preSelectedOnlyIn2CompanyIds.splice(index, 1);
                    }
                }
            }
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.loadBulkCompanyGroups();
        });
    },
    methods: {
        submitForm() {
            switch (this.operation) {
                case "compare": {
                    this.comparisonComplete = true;
                    break;
                }
                case 'delete_company_groups': {
                    const companyGroupIds = this.bulkCompanyGroups.map(c => c.id);
                    if (!confirm(`Are you sure you want to delete ${companyGroupIds.length} Company Groups? These Company Groups will be deleted for everyone. This cannot be undone.`)) {
                        return;
                    }

                    this.running = true;
                    this.$axios.post(`/api/company_groups/bulk_delete`, {companyGroupIds}).then(response => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: 'success',
                            title: 'Deleted successfully',
                            message: `${response.data.count} Company Groups(s) have been successfully deleted`,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });
                    break;
                }
            }
        },
        async loadBulkCompanyGroups() {
            this.loading = true;
            const responseFilters = await this.$axios.post("/api/company_groups/save_bulk_filters", {filters: this.filters});
            const hash = responseFilters.data.hash;

            this.$axios.get("api/company_groups/bulk_company_groups", {
                params: {
                    hash
                }
            }).then(response => {
                this.bulkCompanyGroups = response.data["hydra:member"];
            }).finally(() =>{
                this.loading = false;
            });
        },
        cancel() {
            this.selectedIntersectionCompanies = this.selectedOnlyIn1Companies = this.selectedOnlyIn2Companies = []
            this.comparisonComplete = false;
        },
        openCompanyBulk(mode, id) {
            let filter
            switch (mode) {
                case "selected":
                    filter = {
                        companyIds: this.selectedRows.map((row) => {
                            return row.id
                        })
                    }
                    break;
                case "companyGroup":
                    filter = {
                        companyGroupIds: [id]
                    }
                    break;
                case "intersection":
                    filter = {
                        intersection: [this.bulkCompanyGroups[0].id, this.bulkCompanyGroups[1].id]
                    }
                    break;
                case "difference":
                    // eslint-disable-next-line no-case-declarations
                    const id1 = id;
                    // eslint-disable-next-line no-case-declarations
                    const id2 = this.bulkCompanyGroups.find(c => c.id !== id1).id;
                    filter = {
                        difference: [id1, id2]
                    }
            }

            this.$emit('company-bulk', filter)
            this.cancel();
        },
        onPageChange1() {
            this.pageChanged1 = true;
        },
        onPageChange2() {
            this.pageChanged2 = true;
        },
        onPageChange3() {
            this.pageChanged3 = true;
        },
        onRowsUpdated1(data) {
            this.pageChanged1 = false;
            if (data && data.rows && data.rows.length) {
                this.$nextTick(() => {
                    this.preSelectedIntersection = JSON.parse(JSON.stringify(this.preSelectedIntersectionIds)).map(id => {
                        return {
                            key: "id",
                            value: id
                        }
                    });
                });
            }

            this.ajaxRows1 = data.rows;
            this.allItem1 = data.all;
        },
        onRowsUpdated2(data) {
            this.pageChanged2 = false;
            if (data && data.rows && data.rows.length) {
                this.$nextTick(() => {
                    this.preSelectedOnlyIn1Company = JSON.parse(JSON.stringify(this.preSelectedOnlyIn1CompanyIds)).map(id => {
                        return {
                            key: "id",
                            value: id
                        }
                    });
                });
            }
            this.ajaxRows2 = data.rows;
            this.allItem2 = data.all;
        },
        onRowsUpdated3(data) {
            this.pageChanged3 = false;
            if (data && data.rows && data.rows.length) {
                this.$nextTick(() => {
                    this.preSelectedOnlyIn2Company = JSON.parse(JSON.stringify(this.preSelectedOnlyIn2CompanyIds)).map(id => {
                        return {
                            key: "id",
                            value: id
                        }
                    });
                });
            }
            this.ajaxRows3 = data.rows;
            this.allItem3 = data.all;
        }
    }
}
