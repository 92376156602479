
export default {
    name: "CompanyAnalystCell",
    props: {
        cell: {
            type: Object,
            required: true,
        },
    },
    methods: {
        showCoverage(userId) {
            this.$emit("open", userId)
        }
    }
}
