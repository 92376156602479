
import TableFilters from "../table-filters.vue";
import QuintableSearchInput from "../helpers/quintable-search-input.vue";
import {LABEL_BY_STATUS, STATUS_APPROVED, STATUS_PENDING, STATUS_REJECTED, STATUS_STRONG_CANDIDATE} from "../../mixins/cik-status";
import TableActions from "../table-actions.vue";
import modals from "../../mixins/modals";
import CIKStatusUpdateCell from "./cik-status-update-cell.vue";
import AddCikModal from "./add-cik-modal.vue";

export default {
    name: "CIKTable",
    components: {AddCikModal, TableActions, CIKStatusUpdateCell, QuintableSearchInput, TableFilters},
    mixins: [modals],
    props: {
        company: {
            type: Object,
            default: () => {
            }
        },
        pageSize: {
            type: Number,
            default: 50
        }
    },
    data() {
        return {
            newCik: null,
            STATUS_APPROVED,
            STATUS_PENDING,
            STATUS_REJECTED,
            STATUS_STRONG_CANDIDATE,
            LABEL_BY_STATUS,
            isVisible: false,
            tableUpdated: new Date(),
            config: {
                columns: [
                    {
                        headline: "Company",
                        sort: true,
                    },
                    {
                        headline: "Entity Name",
                    },
                    {
                        headline: "CIK",
                    },
                    {
                        headline: "Status"
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                    }
                ],
                search: true,
                pagination: this.pageSize,
                ajaxUrl: '/companies/cik/list',
                newCik: null,
            },
            searchQuery: null,
            selectedCompanies: [],
            selectedStatuses: [],
            loadingId: null,
        }
    },
    computed: {
        filters() {
            return {
                companies: this.company ? [this.company.id] : this.selectedCompanies.map(company => company.id),
                status: this.selectedStatuses,
            }
        },
    },
    watch: {
    },
    methods: {
        addCik() {
            this.$axios.get("/api/ciks/empty")
                .then((response) => {
                    this.newCik = response.data;
                    this.$nextTick(() => {
                        this.modal = this.openModal(this.$refs.AddCikModal);
                    });
                });
        },
        refreshTable() {
            // Update the tableUpdated timestamp to refresh the table
            this.tableUpdated = new Date();
        },
        resetFilters() {
            this.$refs.ciksTable.setSearchQuery("");
            this.selectedCompanies = [];
        },
        updateSearchQuery(searchQuery) {
            this.searchQuery = searchQuery;
            this.pageChanged = true;
        },
        onPageChange() {
            this.pageChanged = true;
        },
        deleteCIK(id) {
            this.loadingId = id;
            this.$axios.delete(`/api/ciks/${id}`)
                .then(() => {
                    this.tableUpdated = new Date();
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success!",
                        message: "The CIK has been deleted.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                }).finally(() => {
                    this.loadingId = null;
                });
        },
        setPending(id) {
            this.loadingId = id;
            this.$axios.patch(`/api/ciks/${id}`, {
                status: STATUS_PENDING
            }, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            })
                .then((response) => {
                    this.tableUpdated = new Date();
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success!",
                        message: "The CIK status has been updated to pending.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                }).finally(() => {
                    this.loadingId = false;
                });
        }
    }
}
