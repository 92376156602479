
import ContactsTable from "../contacts/contacts-table";

export default {
    name: "CompanyContactList",
    components: {ContactsTable},
    props: {
        company: {
            type: Object,
            required: true
        },
        tableUpdate: {
            type: Date,
            default: null
        },
        showThumbnails: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    computed: {},
    methods: {
        rowClicked(row) {
            this.$emit("show-contact", row.contact_id);
        }
    }
}
