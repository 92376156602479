

import moment from "moment";
import SaveButton from "../save-button";

export default {
    name: "CompanyGroupModal",
    components: {SaveButton},
    props: {
        companies: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            saving: false,
            companiesWorkingCopy: [],
            companyGroup: null,
            companyUpdated: moment().unix(),
            formName: "company-group-form",
            mostUsedCompanyGroups: [],
        }
    },
    computed: {
        companyGroupForSelectUrl() {
            this.resetWorkingCopy();
            return `/api/company_groups/for_select?${this.buildQueryString({excludedCompanyIds: this.companiesWorkingCopy.map(c => c.id)})}`
        },
    },
    watch: {
        companies: {
            handler(val) {
                if (val && val.length) {
                    this.resetWorkingCopy();
                } else {
                    this.companiesWorkingCopy = [];
                }
            },
            immediate: true,
            deep: true
        },
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.loadMostUsedCompanyGroups();
        });
    },
    methods: {
        resetWorkingCopy() {
            this.companiesWorkingCopy = this.companies.map(c => structuredClone(c));

            this.$nextTick(() => {
                this.companyUpdated = moment().unix();
            })
        },
        async submitForm() {
            this.saving = true;

            const companyGroup = (await this.$axios.get(`/api/company_groups/${this.companyGroup.id}`)).data;

            const mergedCompanies = [];

            companyGroup.companyGroupCompanies.forEach((clc) => {
                mergedCompanies.push(clc["@id"]);
            })
            const before = companyGroup.companyGroupCompanies.length;
            console.log("before", before);

            this.companiesWorkingCopy.forEach((company) => {
                if (!companyGroup.companyGroupCompanies.includes(company["@id"])) {
                    mergedCompanies.push({
                        companyGroup: companyGroup["@id"],
                        company: company["@id"],
                    })
                }
            })

            const updatedCompanyGroupCompanies = (await this.$axios.patch(`/api/company_groups/${companyGroup.id}`, {
                companyGroupCompanies: mergedCompanies,
            }, {
                headers: {
                    'Content-Type': 'application/merge-patch+json',
                },
            })).data.companyGroupCompanies;

            const after = updatedCompanyGroupCompanies.length;
            const diff = after - before;

            let type;
            let message;

            if (diff === 0) {
                type = "warning";
                message = "All companies are already in the group.";

            } else {
                type = "success";
                message = `${this.pluralize(diff, 'company is', 'companies were')} added to the group.`;
            }

            const toastId = this.generateUUID();
            await this.addToast({
                type,
                title: "Companies in Group",
                message,
                id: toastId,
            })

            this.$formulate.reset(this.formName);

            this.$nextTick(() => {
                this.toggleToast(toastId);
                this.saving = false;
                this.$nextTick(() => {
                    this.resetWorkingCopy();
                })
            })

            this.$emit("saved");
        },

        loadMostUsedCompanyGroups() {
            return this.$axios.get(`/api/company_groups/most_used?${this.buildQueryString({excludedCompanyIds: this.companiesWorkingCopy.map(c => c.id)})}`)
                .then(response => {
                    this.mostUsedCompanyGroups = response.data["hydra:member"];
                });
        },

    }
}
