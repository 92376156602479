
import loadWhenVisible from "../../../../mixins/loadWhenVisible";
import legalEntities from "../legal-entities.vue";
import modals from "../../../../mixins/modals";
import iriPreparation from "../../../../mixins/iri-preparation";
import LoadingSpinner from "../../../loading-spinner.vue";
import EditLegalEntityAccount from "./edit-account-modal.vue";
import AccountSignatories from "./account-signatories.vue";
import EditLegalEntityAccountSignatory from "./edit-signatory-modal.vue";
import EditLegalEntityAccountArtifact from "./edit-artifact-modal.vue";
import AccountArtifacts from "./account-artifacts.vue";

export default {
    name: "LegalEntityAccounts",
    components: {
        AccountArtifacts,
        EditLegalEntityAccountArtifact,
        LoadingSpinner, EditLegalEntityAccountSignatory, AccountSignatories, EditLegalEntityAccount
    },
    mixins: [loadWhenVisible, modals, iriPreparation],
    props: {
        legalEntity: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            accounts: [],
            collapseStatesByAccountId: {},
            showClosedAccounts: false,
            editLegalEntityAccountKey: "editLegalEntityAccountKey",
            legalEntityAccountToEdit: null,
            editSignatoryKey: "editSignatoryKey",
            editArtifactKey: "editArtifactKey",
            signatoryToEdit: null,
            artifactToEdit: null,
            loading: false,
            arrowKeySuffix: "",
            signatoriesKeyCounter: 0,
            artifactsKeyCounter: 0
        }
    },
    computed: {
        legalEntities() {
            return legalEntities
        },
    },
    watch: {
        loadDataTrigger(value) {
            if (value) {
                this.loadAccounts();
            }
        },
        showClosedAccounts() {
            this.loadAccounts();
        },
    },
    methods: {
        loadAccounts() {
            this.loading = true;
            let url = `/api/legal_entity_accounts?legalEntity=${this.legalEntity.id}`;
            if (!this.showClosedAccounts) {
                url += `&exists[closedOn]=false`;
            }

            this.$axios.get(url)
                .then((response) => {
                    this.accounts = response.data['hydra:member'];
                    for (let i = 0; i < this.accounts.length; i++) {
                        this.collapseStatesByAccountId[this.accounts[i].id] = false;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        toggleCollapseState(accountId) {
            this.collapseStatesByAccountId[accountId] = !this.collapseStatesByAccountId[accountId];
            this.arrowKeySuffix = this.createSimpleHash(JSON.stringify(this.collapseStatesByAccountId));
        },
        closeModalIfExists() {
            if (this.modal) {
                this.closeModal(this.modal);
                this.modal = null;
            }
        },
        addLegalEntityAccount() {
            this.legalEntityAccountToEdit = null;
            this.editLegalEntityAccountKey = this.generateUUID();
            this.$axios.get(`/api/legal_entity_accounts/template/${this.legalEntity.id}`)
                .then((response) => {
                    this.legalEntityAccountToEdit = response.data;
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            this.modal = this.openModal(this.$refs.editLegalEntityAccountModal);
                        });
                    });
                });
        },
        editLegalEntityAccount(account) {
            this.legalEntityAccountToEdit = null;
            this.editLegalEntityAccountKey = this.generateUUID();
            this.legalEntityAccountToEdit = account;
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.modal = this.openModal(this.$refs.editLegalEntityAccountModal);
                });
            });
        },
        saveLegalEntityAccount() {
            this.closeModalIfExists();
            if (this.legalEntityAccountToEdit.id) {
                this.$axios.patch(`/api/legal_entity_accounts/${this.legalEntityAccountToEdit.id}`, this.legalEntityAccountToEdit, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                })
                    .then(() => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Account has been updated",
                            // message: "",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    })
                    .finally(() => {
                        this.legalEntityAccountToEdit = null;
                        this.loadAccounts();
                    });
            } else {
                this.$axios.post('/api/legal_entity_accounts', this.legalEntityAccountToEdit)
                    .then(() => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Account has been added",
                            // message: "",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    })
                    .finally(() => {
                        this.legalEntityAccountToEdit = null;
                        this.loadAccounts();
                    });
            }
        },
        closeLegalEntityAccount() {
            this.closeModalIfExists();
            this.$axios.patch(`/api/legal_entity_accounts/${this.legalEntityAccountToEdit.id}/close`, {},{
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            })
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Account has been closed",
                        // message: "",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                })
                .finally(() => {
                    this.loadAccounts();
                });
        },
        addSignatory(accountId) {
            this.editSignatoryKey = this.generateUUID();
            this.$axios.get(`/api/legal_entity_account_signatories/template/${accountId}`)
                .then((response) => {
                    this.signatoryToEdit = response.data;
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            this.modal = this.openModal(this.$refs.editSignatoryModal);
                        });
                    });
                });
        },
        editSignatory(signatory) {
            this.editSignatoryKey = this.generateUUID();
            this.signatoryToEdit = signatory;
            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.editSignatoryModal);
            });

        },
        deleteSignatory() {
            this.closeModalIfExists();
            this.$axios.delete(`/api/legal_entity_account_signatories/${this.signatoryToEdit.id}`)
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Signatory has been removed",
                        // message: "",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                })
                .finally(() => {
                    this.rerenderSignatories();
                });
        },
        saveSignatory() {
            this.closeModalIfExists();
            this.signatoryToEdit = this.prepareIri(this.signatoryToEdit);

            if (this.signatoryToEdit.id) {
                this.$axios.patch(`/api/legal_entity_account_signatories/${this.signatoryToEdit.id}`, this.signatoryToEdit, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                })
                    .then(() => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Signatory has been updated",
                            // message: "",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    })
                    .finally(() => {
                        this.rerenderSignatories();
                    });
            } else {
                this.$axios.post('/api/legal_entity_account_signatories', this.signatoryToEdit)
                    .then(() => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Signatory has been added",
                            // message: "",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    })
                    .finally(() => {
                        this.rerenderSignatories();
                    });
            }
        },
        addArtifact(accountId) {
            this.artifactToEdit = null;
            this.editArtifactKey = this.generateUUID();
            this.$axios.get(`/api/legal_entity_account_artifacts/template/${accountId}`)
                .then((response) => {
                    this.artifactToEdit = response.data;
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            this.modal = this.openModal(this.$refs.editArtifactModal);
                        });
                    });
                });
        },
        editArtifact(id) {
            this.artifactToEdit = null;
            this.editArtifactKey = this.generateUUID();
            this.$axios.get(`/api/legal_entity_account_artifacts/${id}`)
                .then((response) => {
                    this.artifactToEdit = response.data;
                    // Reformat due date
                    if (this.artifactToEdit.dueDate) {
                        this.artifactToEdit.dueDate = this.artifactToEdit.dueDate.split('T')[0];
                    }
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            this.modal = this.openModal(this.$refs.editArtifactModal);
                        });
                    });
                });
        },
        saveArtifact() {
            this.closeModalIfExists();
            const postData = {
                artifactTag: this.artifactToEdit.artifactTag,
                artifactType: this.artifactToEdit.artifactType,
                artifactTitle: this.artifactToEdit.artifactTitle,
                dueDate: this.artifactToEdit.dueDate,
                validPeriod: this.artifactToEdit.validPeriod,
                includeInReminders: this.artifactToEdit.includeInReminders,
                legalEntityAccountId: this.artifactToEdit.legalEntityAccount.id,
                artifactFile: this.artifactToEdit.artifactFile,
            };

            this.$axios.post(`/api/legal_entity_account_artifacts/add`, postData)
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Artifact has been updated",
                        // message: "",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    this.$nuxt.$emit('artifact-updated');
                })
                .finally(() => {
                    this.rerenderArtifacts();
                });
        },
        deleteArtifact(id) {
            this.closeModalIfExists();
            this.$axios.patch(`/api/legal_entity_account_artifacts/${id}/deprecate`, {}, {
                headers: {
                    'Content-Type': 'application/merge-patch+json',
                },
            })
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Artifact has been deleted",
                        // message: "",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                })
                .finally(() => {
                    this.rerenderArtifacts();
                });
        },
        rerenderSignatories() {
            this.signatoriesKeyCounter += 1;
        },
        rerenderArtifacts() {
            this.artifactsKeyCounter += 1;
        }
    }
}
