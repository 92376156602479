
import SaveButton from "../../../save-button.vue";

export default {
    name: "EditLegalEntityAccountArtifact",
    components: {SaveButton},
    props: {
        id: {
            type: String,
            default: "editLegalEntityAccountArtifactModal"
        },
        artifactToEdit: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            artifactWorkingCopy: null,
        }
    },
    computed: {},
    watch: {
        "artifactToEdit.id": {
            handler() {
                this.artifactWorkingCopy = structuredClone(this.artifactToEdit);
            },
            immediate: true
        },
        artifactWorkingCopy: {
            handler(newValue) {
                this.$emit('update:artifactToEdit', newValue);
            },
            deep: true,
        },
    },
    mounted() {
    },
    methods: {
        submitForm() {
            if (this.artifactWorkingCopy.hasOwnProperty('file') && this.artifactWorkingCopy.file instanceof  File) {

                const formData = new FormData();
                formData.append('file', this.artifactWorkingCopy.file);

                this.$axios.post('/api/media_upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    console.log("Response", response);
                    this.artifactWorkingCopy.artifactFile = response.data.uuid;
                    this.$emit('submit');
                }).catch(error => {
                    console.log("Error", error);
                });
            } else if (this.artifactWorkingCopy.hasOwnProperty('file') && this.artifactWorkingCopy.file !== null) {
                this.artifactWorkingCopy.artifactFile = "00000000-0000-0000-0000-000000000000";
                this.$emit('submit');
            } else {
                this.artifactWorkingCopy.artifactFile = null;
                this.$emit('submit');
            }
        },
        deleteArtifact() {
            if (confirm("You are about to delete the artifact. Do you want to continue?")) {
                this.$emit('delete', this.artifactToEdit.id);
            }
        }
    }
}
