
import SaveButton from "../save-button";

export default {
    name: "DncModal",
    components: {SaveButton},
    props: {
        id: {
            type: String,
            default: "editDNCModal"
        },
        company: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            loading: false,
            dncMode: null,
        }
    },
    watch: {
        company: {
            handler() {
                if (this.company.dnc) {
                    if (['DNC', 'DNC_PER_MV'].includes(this.company.dnc_comment)) {
                        this.dncMode = this.company.dnc_comment;
                    } else {
                        this.dncMode = 'ALLOW';
                        console.error("Invalid DNC Comment", this.company.dnc_comment);
                    }
                } else {
                    this.dncMode = 'ALLOW';
                }
            },
            immediate: true,
        }
    },
    methods: {
        submitForm() {
            this.$axios.post(`/api/companies/dnc/${this.company.id}`, {
                dnc: this.dncMode
            }).then((response) => {
                this.$emit('dnc-updated');
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
