
import SkeletonLines from "../skeleton/skeleton-lines";

export default {
    name: "CompanyStatisticsCard",
    components: {SkeletonLines},
    data() {
        return {
            statistics: null,
        }
    },
    computed: {
        staffedFilters() {
            return this.base64UrlEncodeJahrUtils(JSON.stringify({staffedOnly: true}));
        },
        coveredFilters() {
            return this.base64UrlEncodeJahrUtils(JSON.stringify({coveredOnly: true}));
        },
    },
    created() {
        this.loadCompanyStatistics();
    },
    methods: {
        loadCompanyStatistics() {
            this.$axios.get("/api/companies/coverage_stats")
                .then((response) => {
                    this.statistics = response.data;
                })
        }
    },
}
