

import files from "../../mixins/files";

export default {
    name: "FileNamingModal",
    mixins: [files],
    props: {
        files: {
            type: Array,
            required: true
        },
        id: {
            type: String,
            default: "fileNamingModal"
        },
        isEventFile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fileNames: {},
            filenameValid: {},
            includeInMail: false,
        }
    },
    computed: {},
    watch: {
        files() {
            this.prepareFilenames();
        },
    },
    methods: {
        submitForm() {
            this.$emit('files-named', this.fileNames);
        },
        prepareFilenames() {
            this.files.forEach((file) => {
                const key = this.getFileIdentifier(file);
                this.$set(this.fileNames, key, {name: file.name, includeInMail: false});

            });
        },
    }
}
