
import PercentageChange from "../../helpers/percentage-change";
import CurrencyChange from "../../helpers/currency-change";

export default {
    name: "CompanyChangeCell",
    components: {CurrencyChange, PercentageChange},
    props: {
        cell: {
            type: Object,
            required: true,
        },
    },
}
