

export default {
    name: "ClientManagementOverview",
    props: {
        company: {
            type: Object,
            required: true,
        }
    },
    methods: {}
}
