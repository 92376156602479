import { render, staticRenderFns } from "./unfinished-onboarding-card.vue?vue&type=template&id=66791dce&scoped=true"
import script from "./unfinished-onboarding-card.vue?vue&type=script&lang=js"
export * from "./unfinished-onboarding-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66791dce",
  null
  
)

export default component.exports