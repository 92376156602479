
import LoadingSpinner from "../loading-spinner";

export default {
    name: "DownloadPeerGroupModal",
    components: {LoadingSpinner},

    props: {
        id: {
            type: String,
            default: "newDownloadPeerGroupModal"
        },
        company: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: () => "automatic"
        }
    },
    data() {
        return {
            mode: 'peers',
            downloadOptions: [
                {value: 'peers', label: 'Peer Group'},
                {value: 'ownership', label: 'Ownership Analysis'}
            ],
            // withInstitutionalHolders: false,
            loading: false,
            ownershipLoaded: false,
            cacheKey: '',
        }
    },
    computed: {
        withInstitutionalHolders() {
            return this.mode === 'ownership';
        }
    },
    methods: {
        downloadPeerGroups() {
            this.ownershipLoaded = false;
            if (this.mode === 'ownership') {
                const params = {
                    type: this.type
                };
                const query = this.buildQueryString(params);

                this.loading = true;
                this.$axios.get("/api/companies/excel_export/peer_ownership_prepare/" + this.company.id + "?" + query)
                    .then((response) => {
                        this.cacheKey = response.data.cacheKey;
                        this.ownershipLoaded = true;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                window.open(
                    this.$axios.defaults.baseURL
                        + "/api/companies/excel_export/peer_groups/" + this.company.id,
                    '_blank'
                ).focus();
            }
        },
    }
}
