

import TableActions from "../../../table-actions.vue";
import TableFilters from "../../../table-filters.vue";
import QuintableSearchInput from "../../../helpers/quintable-search-input.vue";
import fileDownload from "../../../../mixins/file-download";

export default {
    name: "ListLegalEntities",
    components: {QuintableSearchInput, TableFilters, TableActions},
    mixins: [fileDownload],
    props: {},
    data() {
        return {
            isVisible: false,
            config: {
                columns: [
                    {
                        headline: "Company",
                        sort: true,
                    },
                    {
                        headline: "Name",
                        sort: true,
                    },
                    {
                        headline: "Country",
                        sort: true
                    },
                    {
                        headline: "Accounts",
                    },
                    {
                        headline: "Risk Score",
                        sort: true,
                    },
                    {
                        headline: "Onboarding Phase",
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                        hidden: true,
                    }
                ],
                search: true,
                pagination: 50,
                ajaxUrl: '/api/legal_entities/list',
            },
            searchQuery: null,
            legalEntitiesKeyCount: 0,
            selectedCompanies: [],
            selectedAccounTypes: [],
        }
    },
    computed: {
        filters() {
            return {
                companyIds: this.selectedCompanies.map(company => company.id),
                accountTypes: this.selectedAccounTypes,
            }
        },
    },
    watch: {},
    methods: {
        resetFilters() {
            this.$refs.legalEntityTable.setSearchQuery("");
            this.$nextTick(() => {
                this.legalEntitiesKeyCount++;
            });
        },
        updateSearchQuery(searchQuery) {
            this.searchQuery = searchQuery;
            this.pageChanged = true;
        },
        onPageChange() {
            this.pageChanged = true;
        },
        delete() {
            // alert("Deleting!");
        },
        downloadLegalEntities() {
            const query = {
                search: this.searchQuery,
                filters: this.filters,
                sort: this.sortOrder,
            }
            this.saveQueryAndExport(
                query,
                `/api/companies/legal_entities/excel_export?hash=${this.companyHash}`
            );
        }
    }
}
