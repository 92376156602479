

export default {
    name: "StartOnboarding",
    props: {
        company: {
            type: Object,
            required: true,
        }
    },
    methods: {
        startOnboarding() {
            if (!confirm("A legal entity will be created. It can be edited afterwards. Do you want to continue?")) {
                return;
            }

            this.$axios.post(`/api/client_management/onboard/${this.company.id}`)
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Onboarding Started",
                        // message: "",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });

                    this.$router.push(`/companies/compliance/${this.company.id}`);
                });
        }
    }
}
