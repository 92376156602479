
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from "moment";

export default {
    name: "CompanyDataPointQuickEdit",
    components: {flatPickr},
    props: {
        label: {
            type: String,
            required: true,
        },
        companyId: {
            type: Number,
            required: true,
        },
        initialData: {
            type: Object,
            required: false,
            default: null,
        },
        companyDataPointType: {
            type: String,
            required: true,
        },
        includePeriod: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            dateConfig: {
                altInput: true,
                altFormat: "m/d/Y",
                dateFormat: "Y-m-d",
            },
            editCompanyDataPoint: false,
            updatedValue: null,
            updatedCurrency: null,
            updatedDate: null,
            updatedUserDataSource: null,
            updatedPeriod: 'month',
            periodOptions: [
                {
                    title: "Per Month",
                    value: 'month',
                },
                {
                    title: "Per Quarter",
                    value: 'quarter',
                },
                {
                    title: "Per Year",
                    value: 'year',
                },
            ],
            isProfitable: false,
            notifyCashBurnMaintainers: false,
        }
    },
    computed: {
        formattedValue() {
            if (this.initialData && this.initialData.value && this.initialData.value > 0) {
                if (this.initialData.currency) {
                    return this.nFormatCurrency(this.initialData.currency, this.initialData.value);
                } else {
                    return this.nFormatter(this.initialData.value);
                }
            }

            return null;
        },
        formattedDate() {
            if (this.initialData && this.initialData.date) {
                return this.formatDate(this.initialData.date)
            }

            return null;
        },
    },
    methods: {
        closeEditCompanyDataPoint() {
            this.editCompanyDataPoint = false;
        },
        openEditCompanyDataPoint() {
            this.updatedCurrency = "USD";
            this.updatedValue = "";
            this.updatedDate = moment().format("YYYY-MM-DD");
            this.updatedUserDataSource = "";
            this.updatedPeriod = 'month';

            if (this.initialData) {
                if (this.initialData.currency && this.initialData.currency !== "-") {
                    this.updatedCurrency = this.initialData.currency;
                }

                if(this.initialData.value && this.initialData.value === "Profitable") {
                    this.isProfitable = true;
                } else if (this.initialData.value && this.initialData.value > 0) {
                    this.updatedValue = parseInt(this.initialData.value) / 1000000;
                }

                if (this.initialData.date) {
                    this.updatedDate = this.initialData.date;
                }

                if (this.initialData.userDataSource) {
                    this.updatedUserDataSource = this.initialData.userDataSource;
                }

                if (this.initialData.period) {
                    this.updatedPeriod = this.initialData.period;
                }
            }

            this.editCompanyDataPoint = true;
        },
        saveUpdatedCompanyDataPoint() {
            // To be sure, replace all ; with ,
            let currency = "";
            let value = "";
            if (this.isProfitable) {
                currency = "-";
                value = "Profitable";
            } else {
                currency = this.updatedCurrency;
                if (currency) {
                    currency = currency.replace(';', ',').trim();
                }

                value = this.updatedValue;
                if (value) {
                    value = Math.round(parseFloat(value) * 1000000);
                } else {
                    value = 0;
                }
            }
            let data = `${currency};${value};${this.updatedDate};${this.updatedUserDataSource}`;

            if (this.includePeriod) {
                data += `;${this.updatedPeriod}`
            }

            const companyDataPoint = {
                source: `User #${this.userInfo.id} (${this.userInfo.name})`,
                type: this.companyDataPointType,
                data,
                notifyCashBurnMaintainers: this.notifyCashBurnMaintainers,
                company: `/api/companies/${this.companyId}`
            };

            if (this.initialData && this.initialData.companyDataPointId) {
                this.$axios.patch(`/api/company_data_points/${this.initialData.companyDataPointId}`, companyDataPoint, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                }).then(() => {
                    this.$emit('updated');
                }).finally(() => {
                    this.closeEditCompanyDataPoint();
                });
            } else {
                this.$axios.post('/api/company_data_points', companyDataPoint
                ).then(() => {
                    this.$emit('updated');
                }).finally(() => {
                    this.closeEditCompanyDataPoint();
                });
            }
        },
    }
}
