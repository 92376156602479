
import modals from "../../../mixins/modals";
import TableActions from "../../table-actions";
import EditMilestoneModal from "./edit-milestone-modal";

export default {
    name: "Milestones",
    components: {TableActions, EditMilestoneModal},
    mixins: [modals],
    props: {
        company: {
            type: Object,
            required: true,
        },
        milestones: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            config: {
                columns: [
                    {
                        headline: "Asset",
                    }, {
                        headline: "Indication",
                    }, {
                        headline: "Stage",
                    }, {
                        headline: "Catalyst",
                    }, {
                        headline: "Timing",
                    }, {
                        headline: "Impact",
                    }, {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: 'end'
                    }
                ],
            },
            modal: null,
            milestoneToEdit: null,
        }
    },
    computed: {
        rows() {
            return this.milestones.map((milestone) => {
                return [
                    {text: milestone.asset},
                    {text: milestone.indication},
                    {text: milestone.stage},
                    {text: milestone.catalyst},
                    // eslint-disable-next-line
                    {text: (milestone.period ? milestone.period + " " : "") + (milestone.year ? milestone.year : "")},
                    {type: 'impact', impact: milestone.impact},
                    {type: 'actions', id: milestone.id},
                ];
            });
        }
    },
    methods: {
        openMilestoneModal() {
            this.modal = this.openModal(this.$refs.editMilestoneModal);
        },
        onModalClose() {
            if (this.modal) {
                this.closeModal(this.modal);
            }
            this.$emit('reload-data');
        },
        deleteMilestone(id) {
            if (!this.isUserWithRoleResearchAnalyst) {
                return;
            }
            this.$axios.delete('/api/company_milestones/' + id)
                .finally(() => {
                    this.$emit('reload-data');
                });
        },
        editMilestone(id) {
            if (!this.isUserWithRoleResearchAnalyst) {
                return;
            }
            this.$axios.get('/api/company_milestones/' + id)
                .then((response) => {
                    this.milestoneToEdit = response.data;
                    this.openMilestoneModal();
                });
        },
        addMilestone() {
            if (!this.isUserWithRoleResearchAnalyst) {
                return;
            }
            this.$axios.get('/api/company_milestone/template/' + this.company.id)
                .then((response) => {
                    this.milestoneToEdit = response.data;
                    this.openMilestoneModal();
                });
        }
    }
}
