
import moment from "moment";

export default {
    name: "BalanceSheetsCard",
    components: {},
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            resultType: ['Yearly', 'Quarterly'],
            selectedType: 'Quarterly',
            companyWorkingCopy: {},
            balanceSheets: [],
            rowsUpdated: moment().unix(),
            config: {
                columns: [
                    {
                        headline: "Filling Date",
                        sort: true
                    }, {
                        headline: "Accepted Date",
                        breakpoint: "all"
                    }, {
                        headline: "Period",
                        breakpoint: "all"
                    }, {
                        headline: "Cash and Cash Equivalents",
                        align: "end"
                    }, {
                        headline: "Short Term Investments",
                        align: "end",
                    }, {
                        headline: "Cash and Short Term Investments",
                        align: "end",
                        breakpoint: "xl",
                    }, {
                        headline: "Net Receivables",
                        align: "end",
                        breakpoint: "xl",
                    }, {
                        headline: "Inventory",
                        align: "end",
                        breakpoint: "xl",
                    }, {
                        headline: "Other current Assets",
                        breakpoint: "all",
                    }, {
                        headline: "Total current Assets",
                        breakpoint: "all"
                    }, {
                        headline: "Property Plant Equipment Net",
                        breakpoint: "all"
                    }, {
                        headline: "Goodwill",
                        breakpoint: "all"
                    }, {
                        headline: "Intangible Assets",
                        breakpoint: "all"
                    }, {
                        headline: "Goodwill and Intangible Assets",
                        breakpoint: "all"
                    }, {
                        headline: "Long Term Investments",
                        breakpoint: "all"
                    }, {
                        headline: "Tax Assets",
                        breakpoint: "all"
                    }, {
                        headline: "Other non current Assets",
                        breakpoint: "all"
                    }, {
                        headline: "Total non current Assets",
                        breakpoint: "all"
                    }, {
                        headline: "Other Assets",
                        breakpoint: "all"
                    }, {
                        headline: "Total Assets",
                        breakpoint: "all"
                    }, {
                        headline: "Account Payables",
                        breakpoint: "all"
                    }, {
                        headline: "Short Term Debt",
                        breakpoint: "all"
                    }, {
                        headline: "Tax Payables",
                        breakpoint: "all"
                    }, {
                        headline: "Deferred Revenue",
                        breakpoint: "all"
                    }, {
                        headline: "Other current Liabilities",
                        breakpoint: "all"
                    }, {
                        headline: "Total current Liabilities",
                        breakpoint: "all"
                    }, {
                        headline: "Long Term Debt",
                        breakpoint: "all"
                    }, {
                        headline: "Deferred Revenue non current",
                        breakpoint: "all"
                    }, {
                        headline: "Deferred Tax Liabilities non current",
                        breakpoint: "all"
                    }, {
                        headline: "Other non current Liabilities",
                        breakpoint: "all"
                    }, {
                        headline: "Total non current Liabilities",
                        breakpoint: "all"
                    }, {
                        headline: "Other Liabilities",
                        breakpoint: "all"
                    }, {
                        headline: "Total Liabilities",
                        breakpoint: "all"
                    }, {
                        headline: "Common Stock",
                        breakpoint: "all"
                    }, {
                        headline: "Retained Earnings",
                        breakpoint: "all"
                    }, {
                        headline: "Accumulated Other Comprehensive Income Loss",
                        breakpoint: "all"
                    }, {
                        headline: "Other total Stockholders Equity",
                        breakpoint: "all"
                    }, {
                        headline: "Total Stockholders Equity",
                        breakpoint: "all"
                    }, {
                        headline: "Total Liabilities and Stockholders Equity",
                        breakpoint: "all"
                    }, {
                        headline: "Total Investments",
                        breakpoint: "all"
                    }, {
                        headline: "Total Debt",
                        breakpoint: "all"
                    }, {
                        headline: "Net Debt",
                        breakpoint: "all"
                    }
                ],
                pagination: 10
            },
            sortOrder: [{
                index: 0,
                asc: false,
            }],
            isTableLoading: false,
        }
    },
    computed: {
        fromDate() {
            if (this.balanceSheets && this.balanceSheets[0]) {
                return moment(this.balanceSheets[0].date, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss"]).format("MM/DD/YYYY")
            }
            return '';
        },
        balanceSheetRows() {
            return this.balanceSheets.map(item => {
                return [
                    {
                        text: moment(item.fillingDate, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss"]).format("MM/DD/YYYY"),
                        sortValue: item.fillingDate
                    }, {
                        text: moment(item.acceptedDate, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss"]).format("MM/DD/YYYY")
                    }, {
                        text: item.period
                    }, {
                        text: this.nFormatter(item.cashAndCashEquivalents, 2)
                    }, {
                        text: this.nFormatter(item.shortTermInvestments, 2)
                    }, {
                        text: this.nFormatter(item.cashAndShortTermInvestments, 2)
                    }, {
                        text: this.nFormatter(item.netReceivables, 2)
                    }, {
                        text: this.nFormatter(item.inventory, 2)
                    }, {
                        text: this.nFormatter(item.otherCurrentAssets, 1)
                    }, {
                        text: this.nFormatter(item.totalCurrentAssets, 1)
                    }, {
                        text: this.nFormatter(item.propertyPlantEquipmentNet, 1)
                    }, {
                        text: this.nFormatter(item.goodwill, 1)
                    }, {
                        text: this.nFormatter(item.intangibleAssets, 1)
                    }, {
                        text: this.nFormatter(item.goodwillAndIntangibleAssets, 1)
                    }, {
                        text: this.nFormatter(item.longTermInvestments, 1)
                    }, {
                        text: this.nFormatter(item.taxAssets, 1)
                    }, {
                        text: this.nFormatter(item.otherNonCurrentAssets, 1)
                    }, {
                        text: this.nFormatter(item.totalNonCurrentAssets, 1)
                    }, {
                        text: this.nFormatter(item.otherAssets, 1)
                    }, {
                        text: this.nFormatter(item.totalAssets, 1)
                    }, {
                        text: this.nFormatter(item.accountPayables, 1)
                    }, {
                        text: this.nFormatter(item.shortTermDebt, 1)
                    }, {
                        text: this.nFormatter(item.taxPayables, 1)
                    }, {
                        text: this.nFormatter(item.deferredRevenue, 1)
                    }, {
                        text: this.nFormatter(item.otherCurrentLiabilities, 1)
                    }, {
                        text: this.nFormatter(item.totalCurrentLiabilities, 1)
                    }, {
                        text: this.nFormatter(item.longTermDebt, 1)
                    }, {
                        text: this.nFormatter(item.deferredRevenueNonCurrent, 1)
                    }, {
                        text: this.nFormatter(item.deferredTaxLiabilitiesNonCurrent, 1)
                    }, {
                        text: this.nFormatter(item.otherNonCurrentLiabilities, 1)
                    }, {
                        text: this.nFormatter(item.totalNonCurrentLiabilities, 1)
                    }, {
                        text: this.nFormatter(item.otherLiabilities, 1)
                    }, {
                        text: this.nFormatter(item.totalLiabilities, 1)
                    }, {
                        text: this.nFormatter(item.commonStock, 1)
                    }, {
                        text: this.nFormatter(item.retainedEarnings, 1)
                    }, {
                        text: this.nFormatter(item.accumulatedOtherComprehensiveIncomeLoss, 1)
                    }, {
                        text: this.nFormatter(item.othertotalStockholdersEquity, 1)
                    }, {
                        text: this.nFormatter(item.totalStockholdersEquity, 1)
                    }, {
                        text: this.nFormatter(item.totalLiabilitiesAndStockholdersEquity, 1)
                    }, {
                        text: this.nFormatter(item.totalInvestments, 1)
                    }, {
                        text: this.nFormatter(item.totalDebt, 1)
                    }, {
                        text: this.nFormatter(item.netDebt, 1)
                    }
                ]
            });
        },
    },
    watch: {
        balanceSheets() {
            this.$nextTick(() => {
                this.rowsUpdated = moment().unix();
            })
        },
        selectedType() {
            this.loadBalanceSheets();
        }
    },
    mounted() {
        this.loadBalanceSheets();
        this.resetWorkingCopy();
    },
    methods: {
        loadBalanceSheets() {
            this.isTableLoading = true;
            if (this.selectedType === "Yearly") {
                this.$axios.get("/api/companies/stock_info/" + this.company.id + "/balance-sheet-statement")
                    .then((response) => {
                        this.balanceSheets = response.data;
                    })
                    .finally(() => {
                        this.isTableLoading = false;
                    });
            } else {
                this.$axios.get("/api/companies/stock_info/" + this.company.id + "/balance-sheet-statement/?period=quarter")
                    .then((response) => {
                        this.balanceSheets = response.data;
                    })
                    .finally(() => {
                        this.isTableLoading = false;
                    });
            }
        },
        resetWorkingCopy() {
            this.companyWorkingCopy = Object.assign({}, this.company);
        },
    }
}
