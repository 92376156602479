import { render, staticRenderFns } from "./add-company-group-reason-modal.vue?vue&type=template&id=0da5cbab&scoped=true"
import script from "./add-company-group-reason-modal.vue?vue&type=script&lang=js"
export * from "./add-company-group-reason-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0da5cbab",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SaveButton: require('/codebuild/output/src214552897/src/web/components/save-button.vue').default})
