
import moment from "moment";
import SaveButton from "../../save-button";

export default {
    name: "EditProjectionModal",
    components: {SaveButton},
    props: {
        id: {
            type: String,
            default: "editProjectionModal"
        },
        projectionToEdit: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            projectionWorkingCopy: {},
            loading: false,
        }
    },
    computed: {
        updateUrl() {
            return '/api/company_projections';
        },
        upcomingYears() {
            const currentYear = Number(moment().year());
            const upcomingYears = [];
            for(let i = 0; i < 10; i++){
                upcomingYears.push(currentYear + i);
            }
            return upcomingYears;
        }
    },
    watch: {
        projectionToEdit() {
            this.resetWorkingCopy();
        }
    },
    mounted() {
        this.resetWorkingCopy();
    },
    methods: {
        resetWorkingCopy() {
            this.projectionWorkingCopy = Object.assign({}, this.projectionToEdit);
        },
        submitForm() {
            this.loading = true;


            if(this.projectionWorkingCopy.year) {
                this.projectionWorkingCopy.year = Number(this.projectionWorkingCopy.year);
            }
            if(this.projectionWorkingCopy.revenue) {
                this.projectionWorkingCopy.revenue = Number(this.projectionWorkingCopy.revenue);
            }
            if(this.projectionWorkingCopy.eps) {
                this.projectionWorkingCopy.eps = Number(this.projectionWorkingCopy.eps);
            }

            if(this.projectionWorkingCopy.id > 0) {
                this.$axios.patch(this.updateUrl + '/' + this.projectionWorkingCopy.id, this.projectionWorkingCopy, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                })
                    .then((response) => {
                        this.$emit('company-updated');
                    }).finally(() => {
                        this.loading = false;
                        this.$emit('modal-close');
                    });
            } else {
                this.$axios.post(this.updateUrl, this.projectionWorkingCopy)
                    .then((response) => {
                        this.$emit('company-updated');
                    }).finally(() => {
                        this.loading = false;
                        this.$emit('modal-close');
                    });
            }
        },
    }
}
