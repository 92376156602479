

import moment from "moment";

export default {
    name: "DuplicateCompanyTable",
    data() {
        return {
            tableLoaded: false,
            pending: false,
            companiesUpdated: moment().unix(),
            rows: [],
            keepCompany: {},
            config: {
                pagination: 30,
                columns: [{
                    headline: "Action",
                }, {
                    headline: "Name",
                }, {
                    headline: "Factset Name",
                }, {
                    headline: "Ticker",
                }, {
                    headline: "Factset ticker",
                }, {
                    headline: "Crunchbase UUID",
                }, {
                    headline: "Website",
                }, {
                    headline: "Reason",
                }, {
                    headline: "Contacts",
                    breakpoint: "lg",
                    align: "end",
                }, {
                    headline: "Bankers",
                    breakpoint: "lg",
                    align: "end",
                }, {
                    headline: "Analysts",
                    breakpoint: "lg",
                    align: "end",
                }, {
                    headline: "Transactions",
                    breakpoint: "lg",
                    align: "end",
                }, {
                    headline: "Interactions",
                    breakpoint: "lg",
                    align: "end",
                }, {
                    headline: "Events",
                    breakpoint: "lg",
                    align: "end",
                }, {
                    headline: "Company Files",
                    breakpoint: "all",
                }, {
                    headline: "Has Logo?",
                    breakpoint: "all",
                }, {
                    headline: "Has Address?",
                    breakpoint: "all",
                }, {
                    headline: "Has Description?",
                    breakpoint: "all",
                }, {
                    headline: "Favorited by",
                    breakpoint: "all",
                }, {
                    headline: "Flagged by",
                    breakpoint: "all",
                }],
                search: true,
            },
            mergeCouplesDict: {},
            ungroupCouplesDict: {},
        }
    },
    computed: {
        mergeCouples() {
            return Object.keys(this.mergeCouplesDict).filter(x => this.mergeCouplesDict[x]);
        },
        ungroupCouples() {
            return Object.keys(this.ungroupCouplesDict).filter(x => this.ungroupCouplesDict[x]);
        },
        selectedRows() {
            return this.mergeCouples.length + this.ungroupCouples.length;
        }
    },
    watch: {
        // selectedRows() {
        //     this.selectedRowIds = this.selectedRows.map(company => company.company_id);
        //     if (this.selectedRowIds.length >= 2) {
        //         this.rows.forEach((row) => {
        //             if (!this.selectedRowIds.includes(row.company_id)) {
        //                 row.disableSelect = true;
        //             }
        //         })
        //     } else {
        //         this.rows.forEach((row) => {
        //             row.disableSelect = false;
        //         })
        //     }
        // },
        companiesUpdated() {
            this.loadDuplicates();
        }
    },
    mounted() {
        this.loadDuplicates();
    },
    methods: {
        reset() {
            this.rows = [];
            this.keepCompany = {};
            this.mergeCouplesDict = {};
            this.ungroupCouplesDict = {};
            this.$nextTick(() => {
                this.companiesUpdated = moment().unix();
            })
        },
        loadDuplicates() {
            this.tableLoaded = false;
            this.$axios.get("/api/companies/duplicate/list")
                .then((response) => {
                    this.rows = response.data.rows;

                    this.rows.forEach((row) => {
                        this.$set(this.keepCompany, row.id, row.company_1_id)
                    })

                }).finally(() => {
                    this.tableLoaded = true;
                })
        },
        async process() {
            this.pending = true;
            for (let i = 0; i < this.mergeCouples.length; i++) {
                const split = this.mergeCouples[i].split(":");
                const id1 = parseInt(split[0]);
                const id2 = parseInt(split[1]);

                const keepCompany = this.keepCompany[this.mergeCouples[i]];
                const deleteCompany = keepCompany === id1 ? id2 : id1;

                await this.$axios.post("/api/companies/merge_delete", {
                    replaceById: keepCompany,
                    toDeleteId: deleteCompany,
                }).then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Merged",
                        message: "Company #" + keepCompany + " merged with company #" + deleteCompany,
                        id: toastId,
                    });

                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                });
            }
            for (let i = 0; i < this.ungroupCouples.length; i++) {
                const split = this.ungroupCouples[i].split(":");
                const id1 = split[0];
                const id2 = split[1];
                await this.$axios.post("/api/companies/mark_as_no_duplicate", {
                    company1Id: id1,
                    company2Id: id2,
                }).then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Marked",
                        message: "Company #" + id1 +
                            "and company #" + id2 +
                            " are marked as no duplicates",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                });
            }
            this.pending = false;
            this.reset();
        }
    }
}
