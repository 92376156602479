
export default {
    name: "CompanyFeedbackCell",
    props: {
        cell: {
            type: Object,
            required: true,
        },
        event: {
            type: Object,
            default: null,
        },
        allowAddFeedback: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    methods: {
        openCompanyFeedback($event) {
            this.$emit('open-company-feedback', $event, this.cell);
        },
    },
}
