

export default {
    name: "OnboardingStatus",
    props: {
        legalEntity: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        finishOnboarding() {
            if (!confirm("Are you sure you want to finish onboarding?")) {
                return;
            }

            this.loading = true;
            this.$axios.patch(`/api/legal_entities/${this.legalEntity.id}/finish-onboarding`, {}, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            })
                .then(() => {
                    this.$emit("updated");
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
