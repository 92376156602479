
import SaveButton from "../../../save-button.vue";

export default {
    name: "EditLegalEntityAccount",
    components: {SaveButton},
    props: {
        id: {
            type: String,
            default: "editLegalEntityAccountModal"
        },
        legalEntityAccountToEdit: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            legalEntityAccountWorkingCopy: null,
        }
    },
    computed: {},
    watch: {
        "legalEntityAccountToEdit.id": {
            handler() {
                this.legalEntityAccountWorkingCopy = structuredClone(this.legalEntityAccountToEdit);
            },
            immediate: true
        },
        legalEntityAccountWorkingCopy: {
            handler(newValue) {
                this.$emit('update:legalEntityAccountToEdit', newValue);
            },
            deep: true,
        },
    },
    mounted() {
    },
    methods: {
        submitForm() {
            this.$emit('submit');
        },
        closeAccount() {
            if (confirm("You are about to close the account. Do you want to continue?")) {
                this.$nextTick(() => {
                    this.$emit('close-account');
                });
            }
        }
    }
}
