


export default {
    name: "CompanySheetsDownloadModal",
    props: {
        companyId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            sheetToDownload: null,
            options: [],
            loading: false,
        }
    },
    mounted() {
        this.loadSheetVersions();
    },
    methods: {
        loadSheetVersions() {
            this.loading = true;
            this.$axios.get('/api/company_sheets/list/' + this.companyId)
                .then((response) => {
                    this.options = response.data.map((version) => {
                        return {value: version.url, label: this.formatDateTime(version.date)}
                    }).sort(function (a, b) {
                        return new Date(b.label) - new Date(a.label)
                    })
                }).finally(() => {
                    this.loading = false;
                    this.sheetToDownload = this.options.length > 0 ? this.options[0].value : null;
                })
        },
        downloadSheet(url) {
            window.open(url)
        }
    }
}
