
import modals from "../../../mixins/modals";
import loadWhenVisible from "../../../mixins/loadWhenVisible";
import Milestones from "./milestones";
import Projections from "./projections";
import CompanySheetsDownloadModal from "./company-sheets-download-modal";

export default {
    name: "AnalystDataCard",
    components: {Projections, Milestones, CompanySheetsDownloadModal},
    mixins: [modals, loadWhenVisible],
    props: {
        company: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            drivers: [],
            risks: [],
            revisionDate: null,
            revisionDateEditEnabled: false,
            investmentSummary: null,
            investSummaryEditEnabled: false,
            newDriver: '',
            newRisk: '',
            loading: false,
            milestones: [],
            projections: [],
            // sheetToDownload: null,
            // options: [],
        }
    },
    computed: {
        wordSheetUrl() {
            return '/api/company_sheets/generate_word/' + this.company.id;
        },
        pdfSheetUrl() {
            return '/api/company_sheets/generate_pdf/' + this.company.id;
        },
    },
    watch: {
        loadDataTrigger(value) {
            if (value) {
                this.loadAnalystData();
            }
        },
    },
    methods: {
        addItem(type, value) {
            this.loading = true;
            const data = {};
            data[type] = value;
            this.$axios.post('/api/analyst_data/add_point/' + this.company.id, data)
                .then((response) => {
                    this.newDriver = '';
                    this.newRisk = '';
                })
                .finally(() => {
                    this.loading = false;
                    this.loadAnalystData();
                });
        },
        deleteItem(id) {
            this.loading = true;
            this.$axios.delete('/api/analyst_data_points/' + id)
                .finally(() => {
                    this.loading = false;
                    this.loadAnalystData();
                });
        },
        editInvestSummary() {
            if (!this.loading && this.isUserWithRoleResearchAnalyst) {
                this.investSummaryEditEnabled = true;
            }
        },
        cancelInvestSummaryEdit() {
            this.investSummaryEditEnabled = false;
        },
        updateInvestSummary() {
            this.loading = true;
            this.$axios
                .post('/api/analyst_data/investment_summary/' + this.company.id, {
                    investmentSummary: this.investmentSummary,
                })
                .finally(() => {
                    this.loadAnalystData();
                    this.loading = false;
                });
            this.investSummaryEditEnabled = false;
        },
        editRevisionDate() {
            if (!this.loading && this.isUserWithRoleResearchAnalyst) {
                this.revisionDateEditEnabled = true;
            }
        },
        cancelRevisionDateEdit() {
            this.revisionDateEditEnabled = false;
        },
        updateRevisionDate() {
            this.loading = true;
            this.$axios
                .post('/api/analyst_data/revision_date/' + this.company.id, {
                    revisionDate: this.revisionDate,
                })
                .finally(() => {
                    this.loadAnalystData();
                    this.loading = false;
                });
            this.revisionDateEditEnabled = false;
        },
        loadAnalystData() {
            this.loading = true;
            this.$axios.get('/api/analyst_data/prepared/' + this.company.id)
                .then((response) => {
                    this.drivers = response.data.drivers;
                    this.risks = response.data.risks;
                    this.investmentSummary = response.data.investmentSummary ? response.data.investmentSummary.data : "";
                    this.revisionDate = response.data.revisionDate ? response.data.revisionDate.data : "";
                    this.milestones = response.data.companyMilestones;
                    this.projections = response.data.companyProjections;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openDownloadModal() {
            this.openModal(this.$refs.companySheetsDownloadModal)
        }
    }
}
