
import HintText from "../hint-text";
import fileDownload from "../../mixins/file-download";

export default {
    name: "DownloadSelectColumnsForm",
    components: {HintText,},
    mixins: [fileDownload],
    props: {
        excelExportColumnsPath: {
            type: String,
            required: true,
        },
        exportPath: {
            type: String,
            required: true
        },
        query: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            columnsByCategories: null,
            presets: null,
            additionalSheets: null,
            isLoading: false,
        }
    },
    watch: {
        excelExportColumnsPath() {
            this.loadColumns();
        }
    },
    mounted() {
        this.loadColumns();
    },
    methods: {
        loadColumns() {
            this.isLoading = true;

            this.$axios.get(this.excelExportColumnsPath).then(response => {
                const additionalSheets = [];

                for (const additionalSheet of response.data.additionalSheets) {
                    additionalSheets.push({...additionalSheet, checked: false})
                }

                this.additionalSheets = additionalSheets;

                const presets = response.data.presets;
                const columnsByCategories = response.data.columns;

                const initialPreset = presets[0];

                for (const columns of Object.values(columnsByCategories)) {
                    // noinspection JSCheckFunctionSignatures
                    for (const column of Object.values(columns)) {
                        column.checked = column.presets.includes(initialPreset);
                    }
                }

                this.presets = presets;
                this.columnsByCategories = columnsByCategories;
            }).finally(() => {
                this.isLoading = false;
            });
        },
        updateAllColumns(newValue) {
            for (const columns of Object.values(this.columnsByCategories)) {
                // noinspection JSCheckFunctionSignatures
                for (const column of Object.values(columns)) {
                    column.checked = newValue;
                }
            }
        },
        updateAllColumnsInGroup(newValue, name) {
            for (const column of Object.values(this.columnsByCategories[name])) {
                column.checked = newValue;
            }
        },
        usePreset(preset) {
            for (const columns of Object.values(this.columnsByCategories)) {
                // noinspection JSCheckFunctionSignatures
                for (const column of Object.values(columns)) {
                    column.checked = column.presets.includes(preset);
                }
            }
            // Additional Sheets
            for (const sheet of Object.values(this.additionalSheets)) {
                if(sheet.hasOwnProperty("presets") === false) {
                    continue;
                }
                sheet.checked = sheet.presets.includes(preset);
            }
        },
        download() {
            this.isLoading = true;

            let currentQuery;
            if (this.query === null) {
                currentQuery = {};
            } else {
                currentQuery = this.query;
            }
            currentQuery.columns = this.columnsByCategories;
            currentQuery.additionalSheets = this.additionalSheets.filter(x => x.checked).map(x => x.name);

            this.saveQueryAndExport(currentQuery, this.exportPath).finally(() => {
                this.isLoading = false;
            });
        },
    }
}
