

import loadWhenVisible from "../../../../mixins/loadWhenVisible";
import ContactName from "../../../contacts/contact-name.vue";
import LoadingSpinner from "../../../loading-spinner.vue";

export default {
    name: "AccountSignatories",
    components: {LoadingSpinner, ContactName},
    mixins: [loadWhenVisible],
    props: {
        account: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            signatories: [],
            loading: false,
        }
    },
    watch: {
        loadDataTrigger(value) {
            if (value) {
                this.loadSignatories();
            }
        },
    },
    methods: {
        loadSignatories() {
            this.loading = true;
            this.$axios.get(`/api/legal_entity_account_signatories?legalEntityAccount=${this.account.id}`)
                .then((response) => {
                    this.signatories = response.data['hydra:member'];
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        editSignatory(signatory) {
            this.$emit('edit-signatory', signatory);
        }
    }
}
