
import SaveButton from "../save-button";

export default {
    name: "EditDescriptionModal",
    components: {SaveButton},
    props: {
        id: {
            type: String,
            default: 'editLinkModal'
        },
        linkToEdit: {
            type: Object,
            default: null,
        },
        isNew: {
            type: Boolean,
            required: true,
        },
        companyId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            linkWorkingCopy: {},
            loading: false,
        }
    },
    computed: {
        updateUrl() {
            return '/api/company_links/' + this.linkWorkingCopy.id;
        },
    },
    watch: {
        linkToEdit() {
            this.resetWorkingCopy();
        },
    },
    mounted() {
        this.resetWorkingCopy();
    },
    methods: {
        resetWorkingCopy() {
            if(this.isNew) {
                this.linkWorkingCopy = {
                    title: null,
                    url: "https://",
                };
            } else {
                this.linkWorkingCopy = Object.assign({}, this.linkToEdit);
            }
        },
        cleanUrl(url) {
            // if(url.startsWith("https://")){}
        },
        submitForm() {
            this.loading = true;
            if(this.isNew) {
                this.$axios.post("/api/company_links", {
                    "title": this.linkWorkingCopy.title,
                    "url": this.linkWorkingCopy.url,
                    "company": "/api/companies/" + this.companyId,
                }).then((response) => {
                    this.$emit('company-updated');
                }).finally(() => {
                    this.loading = false;
                });
            } else {
                this.$axios.patch(this.updateUrl, this.linkWorkingCopy, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                }).then((response) => {
                    this.$emit('company-updated');
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
    }
}
