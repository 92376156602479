import { render, staticRenderFns } from "./account-artifacts.vue?vue&type=template&id=93697764&scoped=true"
import script from "./account-artifacts.vue?vue&type=script&lang=js"
export * from "./account-artifacts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93697764",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableActions: require('/codebuild/output/src214552897/src/web/components/table-actions.vue').default})
