
import moment from "moment/moment";
import modals from "../../mixins/modals";
import CompanyBulkOperationModal from "./company-bulk-operation-modal";

export default {
    name: "CompanyBulkOperationsRow",
    components: {CompanyBulkOperationModal},
    mixins: [modals],
    props: {
        selectedRows: {
            type: Array,
            required: true
        },
        allRows: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            bulkUpdated: moment().unix(),
            bulkTriggered: false,
            mode: "filtered",
            bulkModal: null
        }
    },
    computed: {
        bulkFilters() {
            switch (this.mode) {
                case "selected":
                    return {
                        companyIds: structuredClone(this.selectedRows),
                    }
                default:
                    return {
                        companyIds: structuredClone(this.allRows)
                    };
            }
        },
    },
    methods: {
        openBulkOperationModal(mode) {
            this.mode = mode;
            this.bulkUpdated = moment().unix();
            this.bulkTriggered = true;

            this.$nextTick(() => {
                this.bulkModal = this.openModal(this.$refs.companyBulkOperationModal)
            })
        },
        onBulkSubmitted() {
            // TODO Add an event to which another form can listen - bulkOperationCompleted
            // this.updateTable();
            this.closeModal(this.bulkModal);
        },

    }
}
