

import ATMOTable from "./ATMO-table.vue";

export default {
    name: "ATMOCard",
    components: {ATMOTable},
    props: {
        company: {
            type: Object,
            required: true
        }
    }
}
